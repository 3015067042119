/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { CarrierRequest, } from '../../../types';

// Schemat walidacji zakładania i aktualizacji Customer (Klient)
// @ts-ignore
export const carrierSchema: yup.ObjectSchema<
  CarrierRequest,
  yup.AnyObject,
   {

  //  city: string;
  //  zipCode: string;
  //    street: string;
  //    streetNumber: string;
  //    direction: string | null | undefined;
  //    lng: number | null | undefined;
  //    lat: number | null | undefined;
  //    width: number;
  //    height: number;
  //  isActive: boolean;
  //    isLight: boolean;
  //    carrierMaterial: string;
  //    comment: string | null | undefined;
  },
  ''
> = yup.object().shape({
  
  city: yup
    .string()
    .required('Miasto nie może być puste.')
    .max(
      60, 'Miasto powinno zawierać max 60 znaków.'
    ),
  zipCode: yup
    .string()
    .required('Kod pocztowy nie może być pusty.')
    .max(
      6, 'Kod pocztowy powinien zawierać max 6 znaków.'
    )
    .matches(
      /^\d{2}-\d{3}$/, 'Kod pocztowy musi mieć format XX-XXX.'
    ),

  street: yup
    .string()
    .required('Nazwa ulicy nie możebyć pusta')
    .min(
      3, 'Nazwa ulicy powinna zawierać min 3 znaki.'
    )
    .max(
      60, 'Nazwa ulicy powinna zawierać max 60 znaków.'
    ),

  streetNumber: yup
    .string()
    .required('Numer ulicy nie może być pusty.')
    .min(
      1, 'Numer ulic powinien zawierać min 1 znak.'
    )
    .max(
      10, 'Numer ulicy powinien zawierać max 10 znaków.'
    ),

  direction: yup
    .string()
    .notRequired()
    .max(
      60, 'Kierunek powinien zawierać max 60 znaków.'
    ),

  lon: yup
    .number()
    .notRequired()
    .positive('Liczba musi być dodatnia.')
    .transform((
      value, originalValue
    ) => {

      // Jeśli oryginalna wartość jest pustym ciągiem, zwróć null
      if (String(originalValue).trim() === '') return null;

      // Zamień przecinek na kropkę
      const transformedValue = parseFloat(String(originalValue).replace(
        ',', '.'
      ));
      return isNaN(transformedValue) ? value : transformedValue;
    }),

  lat: yup
    .number()
    .notRequired()
    .transform((
      value, originalValue
    ) => {

      // Jeśli oryginalna wartość jest pustym ciągiem, zwróć null
      if (String(originalValue).trim() === '') return null;

      // Zamień przecinek na kropkę
      const transformedValue = parseFloat(String(originalValue).replace(
        ',', '.'
      ));
      return isNaN(transformedValue) ? value : transformedValue;
    }),

  width: yup
    .number()
    .notRequired()
    .positive('Liczba musi być dodatnia.')
    .integer('Liczba musi być liczbą całkowitą.')
    .transform((
      value, originalValue
    ) => 
      String(originalValue).trim() === '' ? null : value)
    .required('Wysokość nośnika nie może być pusta.')
    .min(
      1, 'Minimalna wysokość to 1 m.'
    )
    .max(
      1000, 'Maksymalna wysokość nośnika to 1000 m.'
    ),

  height: yup
    .number()
    .notRequired()
    .positive('Liczba musi być dodatnia.')
    .integer('Liczba musi być liczbą całkowitą.')
    .transform((
      value, originalValue
    ) => 
      String(originalValue).trim() === '' ? null : value)
    .required('Szerokość nośnika nie może być pusta.')
    .min(
      1, 'Minimalna szerokość to 1 m.'
    )
    .max(
      1000, 'Maksymalna szerokość nośnika to 1000 m.'
    ),

  isActive: yup
    .boolean()
    .required(),

  isLight: yup
    .boolean()
    .required(),
  
  carrierMaterial: yup
    .string()
    .required('Typ nośnika jest wymagany.')
    .max(
      60, 'Kierunek powinien zawierać max 60 znaków.'
    ),

  comment: yup
    .string()
    .notRequired()
    .max(
      1000, 'Opis nośnika powinien zawierać max 1000 znaków.'
    ),
  owner: yup
    .string()
    .nullable(),
});
