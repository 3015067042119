/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import { useQuery, } from 'react-query';
import moment from 'moment';
import { OwnerEntity, OwnerResponse, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { queryClient, } from '../../..';
import { ArrowBackIosNewOutlinedIcon, CalendarMonthOutlinedIcon, EditOutlinedIcon, InsertDriveFileOutlinedIcon, Loader, PageContainer, PlaceOutlinedIcon, } from '../../../components';
import { OwnerInfo, } from './subPage/OwnerInfo';
import { OwnerContracts, } from './subPage/OwnerContracts';
import { OwnerMap, } from './subPage/OwnerMap';
import { OwnerPayment, } from './subPage/OwnerPayment';
import { OwnerImplement, } from './subPage/OwnerImplement';
import { OwnerCarriers, } from './subPage/OwnerCarriers';

export const Owner = () => {

  /* ------------ Przełącznik podstrony -------- */
  const [ page, setPage, ] = useState<string>('info');

  /* ------------ Nawigacja -------- */
  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego właściciela -------- */
  const {
    isLoading:getIsLoading,
    isError: getIsError,
    data:owner,
  } = useQuery(
    [ 'owner', id, ], async (): Promise<OwnerEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/owner/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: OwnerResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('owners');
        return data;
      },
      enabled: !!id,
    }
  );

  /* ------------ Edycja Danych -------- */
  const handleEdit = (id: string|undefined) => {
    navigate(`/wlasciciel/edit/${id}`);
  };

  /* ------------ Powrót -------- */
  const handleBack = () => {
    navigate('/wlasciciel');
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (getIsLoading) return <Loader />;

  if (getIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  return (
    <div>
      <PageContainer>
        <div className='infoHeader'>
          <div className='infoHeader__up' >
            <div className='infoHeader__link'>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleBack()}
              >
                <ArrowBackIosNewOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleEdit(owner?.id)}
              >
                <EditOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
            </div>
          </div>
          <div className='infoHeader__bottom'>
            <h4 className='infoHeader__header' >{ owner?.name }</h4>
            <div className='infoHeader__location'>
              <div className='infoHeader__element'>
                <PlaceOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ owner?.city}</span>
              </div>
              <div className='infoHeader__element'>
                <InsertDriveFileOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ owner?.NIP }</span>
              </div>
              <div className='infoHeader__element'>
                <CalendarMonthOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ moment(owner?.registrationDate).format('YYYY-MM-DD')}</span>
              </div>
            </div>
            <div className='infoHeader__stats'>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>$500</h5>
                <p className='infoHeader__statsDesc'>Zysk</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>12</h5>
                <p className='infoHeader__statsDesc'>Umowy</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader infoHeader__statsHeader--red'>$5500</h5>
                <p className='infoHeader__statsDesc'>Wszystkie</p>
              </div>
            </div>
          </div>
          <nav className='infoNav'>
            <ul className='infoNav__list'>
              <li
                className={`infoNav__itemList ${page ==='info' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('info')}
              >Informacje</li>
              <li
                className={`infoNav__itemList ${page ==='carriers' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('carriers')}
              >Nośniki</li>
              <li
                className={`infoNav__itemList ${page ==='umowy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('umowy')}
              >Umowy</li>
              <li
                className={`infoNav__itemList ${page ==='mapy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('mapy')}
              >Mapa</li>
              <li
                className={`infoNav__itemList ${page ==='realizacje' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('realizacje')}
              >Realizacje</li>
              <li
                className={`infoNav__itemList ${page ==='platnosc' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('platnosc')}
              >Płatności</li>
            </ul>
          </nav>
        </div>
        <div className='wrapper'>
          {owner && page === 'info' && <OwnerInfo owner={ owner } />}
          {owner && page === 'carriers' && <OwnerCarriers owner={ owner } />}
          {id && page === 'umowy' && <OwnerContracts id={ id } />}
          {id && page ==='mapy' && <OwnerMap id={ id }/>}
          {id && page ==='realizacje' && <OwnerImplement id={ id }/>}
          {id && page ==='platnosc' && <OwnerPayment id={ id }/>}
        </div>
      </PageContainer>
    </div>
  );
};
