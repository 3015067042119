import React, { ReactNode, } from 'react';
import './PageContainer.css';

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer = ({ children, }: PageContainerProps) => {
  return <div className='pageContainer'>{children}</div>;
};
