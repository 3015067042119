import React from 'react';

interface Props {
  id: string;
}
export const CustomerImplement = ({ id, }:Props) => {
  return (
    <div>
      realizacje { id }
    </div>
  );
};
