/* eslint-disable react/react-in-jsx-scope */
import { PhotoSizeSelectActualOutlinedIcon, } from '../../../../components';
import { CarrierEntity, OwnerEntity, } from '../../../../types';

interface Props {
  owner: OwnerEntity;
}

export const OwnerCarriers = ({ owner, }: Props) => {

  const { carriers, } = owner;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <div className='customerInfo'>
      <div className='customerInfo__left' >

        <ul className='customerInfo__card' style={{ width: '96%', }}>
          <div style={{ width: '100%', padding: '0 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', }}>
            <PhotoSizeSelectActualOutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} />
            <h5 className='customerInfo__header'>Nośniki:</h5>
          </div>
          {carriers.length >= 1 ? (carriers.map((
            carrier:CarrierEntity, index:number
          ) => 
            <li key={carrier.id} style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around', gap: '20px', }}>
              <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', flex: 1, }}>{index+1}. { carrier?.city } </p>
              <p className='customerInfo__data' style={{ flex: 1, }}>ulica: { carrier.street } { carrier.streetNumber }</p>
              <p className='customerInfo__data' style={{ flex: 1, }}>oświetlenie: {carrier.isLight ? 'tak':'nie'} </p>
            </li>)) : <p>Barak nośników przypisanych do właściciela</p> }
        </ul>
      </div>
    </div>
  );
};
