/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import React, {
  BaseSyntheticEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import './Input.css';
import { FieldError, } from 'react-hook-form';
import { ServerErrors, } from '../../types';
import { ErrorInput, } from '../errors/ErrorInput';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  min?: number;
  max?: number;
  name: string;
  id: string;
  placeholder: string;
  focus?: boolean;
  defaultValue?: number;
  handleChange?: (e: BaseSyntheticEvent) => void;
  register: any;
  errors: FieldError | undefined;
  serverErrors?: ServerErrors | null;
  validate?: string
  
}

export const InputNumber = ({
  name,
  id,
  max,
  min,
  step,
  placeholder,
  focus,
  handleChange,
  required,
  register,
  errors,
  serverErrors,
  validate,
  value,
  disabled,
  ...rest
}: InputProps) => {

  const hasValue = value != null && value !== '';

  // Ref
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => {
          focus && inputRef?.current?.focus();
        }, 200
      );
    }, [ focus, ]
  );
  
  return (
    <div className='input'>
      <input
        ref={inputRef}
        className={`input__data ${required && 'required'}`}
        type='number'
        name={name}
        id={id}
        autoComplete='off'
        tabIndex={0}
        max={max}
        min={min}
        step={step}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        required={required}
        disabled={disabled}
        style={{ backgroundColor: disabled ? 'rgb(140, 163, 186)' : '', }}
        {...register(name)}
        {...rest}
      />
      <label className={`input__label ${hasValue || disabled ? 'has-value' : ''}`} style={{ backgroundColor: disabled ? 'transparent' : '', color: disabled ? 'transparent' :'', }} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>
      {!errors && !serverErrors && <div style={{ height: '20px', }}/>}
      <ErrorInput
        errors={errors}
        serverErrors={serverErrors}
        errorName={name}
        validate={validate}
      />
    </div>
  );
};
