/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
import React, { Dispatch, HTMLProps, SetStateAction, useState, } from 'react';
import { useMutation, useQuery, } from 'react-query';
import { BreakLine, Button, ErrorMessage, Loader, SearchBar, } from '../../../components';
import { CONFIG, } from '../../../utils/config';
import { CarrierEntity, OwnerEntity, OwnersResponse, ServerErrors, } from '../../../types';

import './ownersListOne.css';
import { queryClient, } from '../../..';

interface Props extends HTMLProps<HTMLDivElement> {
  viewPopup?: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

export const OwnersList = ({ viewPopup, id, ...rest }: Props) => {

  /* ------------ Logika wyszukiwania włascicieli -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');

  // Stan wybranego właściciela
  const [ selectedOwner, setSelectedOwner, ] = useState<string | null>(null);

  /* ------------ Walidacja Backend -------- */
  
  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);

  /* ------------ Pobieranie wszystkich właścicieli -------- */
  const {
    isLoading,
    isError,
    data: owners,
  } = useQuery(
    'owners', async (): Promise<OwnerEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/owner`, {
          credentials: 'include',
        }
      );
      const { data, }: OwnersResponse = await res.json();
      return data;
    }
  );

  /* ------------ Filtrowanie wyszukiwanych danych -------- */

  const filteredRows = search? owners?.filter(item => {
    const searchData = [
      item.name.toLowerCase(),
      item.nameOwner.toLowerCase(),
      item.NIP.toLowerCase(),
      item.street.toLowerCase(),
      item.zipCode.toLowerCase(),
      item.city.toLowerCase(),
    ].join(' ');

    const carriersData = item?.carriers?.map((carrier:CarrierEntity) => {
      return [
        carrier.city.toLowerCase(),
        carrier.zipCode.toLowerCase(),
        carrier.street.toLowerCase(),
      ].join(' ');
    }).join(' ');

    return (
      searchData.includes(search.toLowerCase()) ||
        carriersData?.includes(search.toLowerCase())
    );
  }): owners;

  /* ------------ Wysyłanie Danych do Backendu -------- */
  const { mutate, /* isLoading */ } = useMutation(
    async () => {
      const response = await fetch(
        `${CONFIG.URL}/api/carrier/owner/${id}`, {
          method     : 'PATCH',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ owner: selectedOwner, }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('carriers');
        queryClient.invalidateQueries([ 'carrier', id, ]);
        viewPopup && viewPopup(false);
      },
      onSettled: newTodo => {
        queryClient.invalidateQueries([ 'carriers', newTodo.id, ]);
      },
    }
  );

  /* ------------ Akcja wysyłania formularza -------- */
  
  const onSubmit = () => {
    mutate();
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  
  if (isLoading) return <Loader />;
  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <div className='carrierMaterialDelete' {...rest}>
      {!isLoading && <div className='empty' />}
      {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
           )}
      <h2 className='carrierMaterialDelete__title'>Wybierz Właściciela</h2>
      <div className='carrierMaterialDelete__buttons' style={{ marginBottom: '10px', }}>
        <Button
          type='button'
          text=' + Dodaj właściciela'
          className='button add'
        />
        <SearchBar handleSearch={setSearch} search={search} />
      </div>
      <BreakLine/>
      <form style={{ minHeight: '200px', maxHeight: '20vh', overflow: 'auto', width: '600px', }}>
        {filteredRows?.map(owner => 
          <div key={owner.id} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px', marginBottom: '10px', }}>
            <input
              type='radio'
              value={owner.id}
              name='owner'
              id={owner.id}
              checked={selectedOwner === owner.id}
              onChange={e => 
                setSelectedOwner(e.target.value)} />
            <label htmlFor={owner.id} >
              {owner.nameOwner} -- {owner.city } -- (<span style={{ fontWeight: 'bold', }}>{owner.NIP}</span>)
            </label>
          </div>)}
      </form>
      <BreakLine/>
      <div className='carrierMaterialDelete__buttons' style={{ marginTop: '10px', }}>
        <Button
          type='button'
          disabled={!selectedOwner}
          text='Wybierz'
          className='button positiv'
          onClick={() =>
            onSubmit()}
        />
        <Button
          type='button'
          text='Anuluj'
          className='button cancel'
          onClick={() => 
            viewPopup && viewPopup(false)}
        />
      </div>
    </div>
  );
};
