import React from 'react';
import { useAuth, } from '../../hooks/useAuth';

import './Navbar.css';
import { DarkModeOutlinedIcon, } from '../icons';
import { TimerDisplay, } from '../timer/TimerDisplay';

export const Navbar = () => {
  const { data, } = useAuth();

  return (
    <header className='navbar'>
      <div className='navbar__wrapper'>
        <div className='navbar__items'>
          <div className='navbar__item'>
            <DarkModeOutlinedIcon className='navbar__icon' />
          </div>
          <div className='navbar__item navbar__item--timer'>
            <TimerDisplay />
          </div>
          <div className='navbar__item'>
            <div className='navbar__avatar'>{data.name.split('')[ 0 ]}</div>
          </div>
        </div>
      </div>
    </header>
  );
};
