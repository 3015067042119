/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useState, } from 'react';
import { useQuery, } from 'react-query';

// import { CarrierMaterialDelete, } from './carrierMaterialDelete/CarrierMaterialDelete';

import {
  AddButton,
  ArticleOutlinedIcon,
  BasicTable,
  HeaderSide,
  Loader,
  PageContainer,
  Popup,
  SearchBar,
} from '../../components';
import { ContractEntity, ContractsResponse, DataColumn, } from '../../types';

import { ContractDelete, } from './contractDelete/ContractDelete';
import { CONFIG, } from '../../utils/config';

import './ContractsView.css';

/* ------------ Powiązanie nagłówka tabeli z danymi -------- */
const columns: DataColumn[] = [
  {
    name: 'Id',
    data: 'id',
  },
  {
    name: 'Numer umowy z klientem',
    data: 'numberContractCustomer',
  },
  {
    name: 'Klient',
    data: 'customer',
  },
  {
    name: 'Miasto',
    data: 'city',
  },
  {
    name: 'Ulica',
    data: 'street',
  },
  {
    name: 'Numer umowy z właścicielem',
    data: 'numberContractOwner',
  },
  {
    name: 'Właściciel',
    data: 'owner',
  },
];
export const Contract = memo(() => {

  /* ------------ Logika wyszukiwania umów -------- */
  const [ search, setSearch, ] = useState<string>('');

  const [ idDelete, setIdDelete, ] = useState<string>('');
  const [ popup, setPopup, ] = useState<boolean>(false);

  /* ------------ Pobieranie wszytkach umów -------- */
  // fetch GET All Contracts
  const {
    isLoading,
    isError,
    data: contracts,
  } = useQuery(
    'contracts', async (): Promise<ContractEntity[]> => {
      const res = await fetch(
        `${CONFIG.URL}/api/contract`, {
          credentials: 'include',
        }
      );
      const { data, }: ContractsResponse = await res.json();
      return data;
    }
  );

  // * Rendering
  if (isLoading) return <Loader />;

  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  return (
    <main className='contractView'>
      <PageContainer>
        <HeaderSide
          title='Umowy'
          icon={<ArticleOutlinedIcon />}
          button={<AddButton
            text='Dodaj nowy'
            action='new'
          />}
        />
        <SearchBar handleSearch={setSearch} search={search} />
        {contracts && (
          <BasicTable
            dataColumns={columns}
            dataRows={contracts}
            viewPopup={setPopup}
            popup={popup}
            setIdDelete={setIdDelete}
            search={search}
          />
        )}
      </PageContainer>
      {popup && idDelete && (
        <Popup viewPopup={setPopup}>
          <ContractDelete id={idDelete} viewPopup={setPopup} />
        </Popup>
      )}
    </main>
  );
});
