/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, } from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useForm, } from 'react-hook-form';
import moment from 'moment';
import { queryClient, } from '../../..';

import {
  ArticleOutlinedIcon,
  Button,
  Fieldset,
  HeaderSide,
  PageContainer,
  Loader,
  ErrorMessage,
  InputText,
  Form,
  InputCheck,
  InputDate,
} from '../../../components';

import { CustomerEntity, CustomerRequest, CustomerResponse, ServerErrors, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { customerSchema, } from './CustomerSchema';

import './CustomerForm.css';

type CustomerFormT = {
  isActive: NonNullable<boolean | undefined>
  NIP: string;
  REGON: string | undefined
  name: string;
  nameCustomer: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  contactPerson: string;
  contactEmail: string;
  registrationDate: Date; // lub string, w zależności od tego, jak przetwarzasz daty
  accountNumber: string | undefined;
  contactTel: string | undefined ;
  contactWww: string | undefined; // Załóżmy, że to pole może być niezdefiniowane
};

export const CustomerForm = () => {

  /* ------------ Nawigacja -------- */

  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego klienta -------- */

  const {
    isLoading:getIsLoading,
    isError:getIsError,
  } = useQuery(
    [ 'customer', id, ], async (): Promise<CustomerEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/customer/${id ?? ''}`, {
          credentials: 'include',
        }
      );
      const { data, }: CustomerResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('customers');
        if (data) {
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              setValue(
key as keyof CustomerRequest, (data as any)[ key ]
              );
            }
          }
        }
        return data;
      },
      enabled: !!id,
    }
  );

  /* ------------ Stan i walidacja Formularza -------- */

  const { setValue,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, }, } = useForm<CustomerFormT>({
      resolver: yupResolver(customerSchema),
      mode    : 'onBlur', // Walidacja na zdarzeniu onBlur
    });

  /* ------------ Walidacja Backend -------- */

  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);
  console.log(serverErrors);

  /* ------------ Wysyłanie Danych do Backendu -------- */

  const { mutate, isLoading, } = useMutation(
    async (data: CustomerRequest) => {
      const response = await fetch(
        `${CONFIG.URL}/api/customer/${id ?? ''}`, {
          method     : id ? 'PATCH' : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ ...data, }),
          credentials: 'include',
        }
      );
      if (!response.ok) {
        const errorData = await response.json(); // Tylko jedno wywołanie .json()
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries([ 'customer', id, ]);
        navigate(-1);
      },
    }
  );

  /* ------------ Zapisywanie danych do LocalStorage -------- */
  useEffect(
    () => {

      // Wczytywanie danych z LocalStorage przy inicjalizacji
      const savedData = localStorage.getItem('customerValues');
      if (savedData) {
        const formValues = JSON.parse(savedData);
        for (const key in formValues) {
          setValue(
            key as keyof CustomerRequest, formValues[ key ]
          );
        }
      }
    }, [ setValue, ]
  );

  useEffect(
    () => {

      // Zapisywanie danych do LocalStorage przy zmianie wartości
      const subscription = watch(value => {
        localStorage.setItem(
          'customerValues', JSON.stringify(value)
        );
      });
      return () => 
        subscription.unsubscribe();
    }, [ watch, ]
  );

  /* ------------ Akcja wysyłania formularza -------- */

  const onSubmit = (data: CustomerRequest) => {
    mutate({ ...data, registrationDate: moment(data.registrationDate).format('YYYY-MM-DD') as unknown as Date, });
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  
  if (getIsLoading) return <Loader />;

  if (getIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  return (
    <div className='customerForm'>
      <PageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          
          <HeaderSide title='Dodawanie Klienta' icon={<ArticleOutlinedIcon />} />
          <Button
            className='button delete'
            type='button'
            text='Reset'
            style={{ marginRight: '20px', }}
            handleClick={() => {
              localStorage.removeItem('customerValues');
              reset();
            }
            }
          />
        </div>
        {!isLoading && <div className='empty' />}
        {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
        )}
        <div className='wrapper'>
          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Fieldset legend='Klient aktywny:' direction='row' style={{ flex: 1, minWidth: '300px', }}>
              <InputCheck
                id='Klient Aktywny'
                name='isActive'
                defaultChecked
                register={register}
              />
            </Fieldset>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <Fieldset legend='Dane Klienta:' direction='row' style={{ flex: 1, minWidth: '300px', }} >
                <InputText
                  placeholder='XXX-XXX-XX-XX'
                  id='NIP'
                  name='NIP'
                  minLength={10}
                  maxLength={13}
                  required
                  register={register}
                  errors={errors.NIP}
                  serverErrors={serverErrors}
                  validate='isUnique'
                  value={watch('NIP')}
                />
                <InputText
                  placeholder='12345678'
                  id='REGON'
                  name='REGON'
                  minLength={8}
                  maxLength={9}
                  register={register}
                  errors={errors.REGON}
                  value={watch('REGON')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='FAM'
                  id='Nazwa własna Klienta'
                  name='name'
                  minLength={3}
                  maxLength={150}
                  required
                  errors={errors.name}
                  register={register}
                  value={watch('name')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='FunArtMedia'
                  id='Nazwa Firmy'
                  name='nameCustomer'
                  minLength={3}
                  maxLength={150}
                  required
                  errors={errors.nameCustomer}
                  register={register}
                  value={watch('nameCustomer')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='Wolności'
                  id='Ulica'
                  name='street'
                  minLength={3}
                  maxLength={60}
                  required
                  errors={errors.street}
                  register={register}
                  value={watch('street')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='19/15'
                  id='Numer ulicy'
                  name='streetNumber'
                  minLength={1}
                  maxLength={10}
                  required
                  errors={errors.streetNumber}
                  register={register}
                  value={watch('streetNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='XX-XXX'
                  id='Kod Pocztowy'
                  name='zipCode'
                  maxLength={6}
                  required
                  errors={errors.zipCode}
                  register={register}
                  value={watch('zipCode')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='Zabrze'
                  id='Miasto'
                  name='city'
                  maxLength={60}
                  required
                  errors={errors.city}
                  register={register}
                  value={watch('city')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
              <Fieldset legend='Dane dodatkowe' direction='row' style={{ flex: 1, minWidth: '300px', }}>
                <InputText
                  placeholder='Imię Nazwisko'
                  id='Osoba do kontaktu'
                  name='contactPerson'
                  minLength={3}
                  maxLength={28}
                  required
                  errors={errors.contactPerson}
                  register={register}
                  value={watch('contactPerson')}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='nazwa@domena.pl'
                  id='Adres Email'
                  name='contactEmail'
                  minLength={5}
                  maxLength={28}
                  errors={errors.contactEmail}
                  required
                  register={register}
                  value={watch('contactEmail')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputDate
                  id='Data rejestracji firmy'
                  name='registrationDate'
                  min={99999}
                  max={0}
                  errors={errors.registrationDate}
                  register={register}
                  style={{ flex: 1, }}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='Format IBAN'
                  id='Numer konta'
                  name='accountNumber'
                  maxLength={28}
                  errors={errors.accountNumber}
                  register={register}
                  value={watch('accountNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}

                <InputText
                  placeholder='+48 XXX-XXX-XXX'
                  id='Numer Telefonu'
                  name='contactTel'
                  minLength={3}
                  maxLength={60}
                  errors={errors.contactTel}
                  register={register}
                  value={watch('contactTel')}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='www.strona.pl'
                  id='strona www'
                  name='contactWww'
                  minLength={3}
                  maxLength={50}
                  errors={errors.contactWww}
                  register={register}
                  value={watch('contactWww')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
            </div>
            <div className='carrierMaterialAdd__buttonsContainer' style={{ backgroundColor: 'white', padding: '24px', }}>
              <Button type='submit' text={id ? 'Aktualizacja' : 'Zapisz'} />
              <Button
                className='button cancel'
                type='button'
                text='Anuluj'
                handleClick={() => {
                  localStorage.removeItem('customerValues');
                  navigate(-1);
                }}
              />
            </div>
          </Form>
        </div>
      </PageContainer>
    </div>
  );
};
