/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { BaseSyntheticEvent, InputHTMLAttributes, } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  register: any;
  defaultChecked?: boolean;
  handleChange?: (e: BaseSyntheticEvent) => void;
}

export const InputCheck = ({
  id,
  name,

  /* handleChange, */
  defaultChecked,
  register,
  ...rest
}: InputProps) => {
  return (
    <div className='checkInput'>
      <input
        type='checkbox'
        className='checkbox'
        name={name}
        id={id}
        autoComplete='off'
        tabIndex={0}
        defaultChecked={defaultChecked}
        {...register(name)}
        {...rest}
      />
      <label htmlFor={id} className='inputCheckbox__label'>
        {id}
      </label>
    </div>
  );
};
