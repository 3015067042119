/* eslint-disable no-unused-expressions */
import React, {
  BaseSyntheticEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import './Input.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  maxLength: number;
  minLength: number;
  name: string;
  id: string;
  placeholder: string;
  focus?: boolean;
  value: string;
  handleChange: (e: BaseSyntheticEvent) => void;
}

export const InputT = ({
  value,
  name,
  id,
  maxLength,
  minLength,
  placeholder,
  focus,
  handleChange,
  required,
  ...rest
}: InputProps) => {

  // Ref
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => {
          focus && inputRef?.current?.focus();
        }, 200
      );
    }, [ focus, ]
  );
  return (
    <div className='input'>
      <input
        ref={inputRef}
        className={`input__data ${required && 'required'}`}
        type='text'
        name={name}
        id={id}
        value={value}
        autoComplete='off'
        tabIndex={0}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={handleChange}
        required
        {...rest}
      />
      <label className={`input__label ${required && 'required'}`} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>
    </div>
  );
};
