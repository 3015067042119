import React, { useState, } from 'react';
import { useQuery, } from 'react-query';

import { CarrierMaterialDelete, } from './carrierMaterialDelete/CarrierMaterialDelete';

import {
  AddButton,
  BasicTable,
  FitbitOutlinedIcon,
  HeaderSide,
  PageContainer,
  Popup,
  SearchBar,
} from '../../components';
import { CarriersMaterialResponse, } from '../../types/carrierMaterial';

import './CarrierMaterialView.css';
import { CONFIG, } from '../../utils/config';

/* ------------ Powiązanie nagłówka tabeli z danymi -------- */
export interface CarrierMaterialDataColumn {
  name: string;
  data: string;
}

export const CarrierMaterial = () => {

  // localState
  const [ idDelete, setIdDelete, ] = useState<string>('');
  const [ carrierMaterialSearch, setCarrierMaterialSearch, ] =
    useState<string>('');
  const [ popup, setPopup, ] = useState<boolean>(false);

  // fetch GET Data Table
  const { isLoading, isError, data, } = useQuery(
    'carrierMaterials', async () => {
      const res = await fetch(
        `${CONFIG.URL}/api/carrier-material`, {
          credentials: 'include',
        }
      );
      const carrierMaterial: CarriersMaterialResponse = await res.json();
      return carrierMaterial.data;
    }
  );

  // View Table
  const columns: CarrierMaterialDataColumn[] = [
    {
      name: 'Id',
      data: 'id',
    },
    {
      name: 'Nazwa',
      data: 'name',
    },
  ];

  // * Rendering
  if (isLoading) return <p>Loading...</p>;

  if (isError) return <p>An error has occurred</p>;

  return (
    <main className='carrierMaterial'>
      <PageContainer>
        <HeaderSide
          title='Typ nośnika'
          icon={<FitbitOutlinedIcon />}
          button={<AddButton
            text='Dodaj nowy'
            action='new'
          />}
        />
        <SearchBar
          handleSearch={setCarrierMaterialSearch}
          search={carrierMaterialSearch}
        />
        {data && (
          <BasicTable
            dataColumns={columns}
            dataRows={data}
            viewPopup={setPopup}
            popup={popup}
            setIdDelete={setIdDelete}
            search={carrierMaterialSearch}
          />
        )}
      </PageContainer>
      {popup && idDelete && (
        <Popup viewPopup={setPopup}>
          <CarrierMaterialDelete id={idDelete} viewPopup={setPopup} />
        </Popup>
      )}
    </main>
  );
};
