import React, { InputHTMLAttributes, ReactNode, } from 'react';
import './Fieldset.css';

interface FieldsetProps extends InputHTMLAttributes<HTMLFieldSetElement>{
  children?: ReactNode;
  direction: 'row' | 'column';
  legend?: string;
}

export const Fieldset = ({ children, legend, direction, ...rest }: FieldsetProps) => {
  return (
    <fieldset className={`fieldset ${direction}`} {...rest}>
      {legend ? <legend className='legend'>{legend}</legend> : null}
      {children}
    </fieldset>
  );
};
