import React from 'react';
import { Outlet, } from 'react-router-dom';
import { Navbar, } from '../../components/navbar/Navbar';
import { SideBar, } from '../../components/sidebar/Sidebar';

import './Layout.css';

export const Layout = () => {
  return (
    <div className='layout'>
      <SideBar />
      <div className='layout__container'>
        <Navbar />
        <Outlet />
      </div>
    </div>
  );
};
