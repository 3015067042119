/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  BaseSyntheticEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import { queryClient, } from '../../..';

import {
  Button,
  FitbitOutlinedIcon,
  Form,
  HeaderSide,
  InputT,
  PageContainer,
} from '../../../components';

import {
  CarrierMaterialEntity,
  CarrierMaterialResponse,
} from '../../../types/carrierMaterial';
import { CONFIG, } from '../../../utils/config';

import './CarrierMaterialForm.css';

export const CarrierMaterialForm = () => {
  const { id, } = useParams();

  const navigate = useNavigate();

  const initialCarrier: CarrierMaterialEntity = {
    name: '',
  };

  // Local state
  const [ fields, setFields, ] = useState({ ...initialCarrier, });

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value, checked, } = e.target;
    setFields({ ...fields, [ name ]: checked || value, });
  };
  const inputElement = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => 
          inputElement.current?.focus(), 200
      );
    }, []
  );

  // fetch GET One Data Table
  useQuery(
    'carrierMaterial', async () => {
      const res = await fetch(
        `${CONFIG.URL}/api/carrier-material/${id ?? ''}`, {
          credentials: 'include',
        }
      );
      const carrierMaterial: CarrierMaterialResponse = await res.json();
      setFields({ name: carrierMaterial.data?.name, });
      return carrierMaterial.data;
    }
  );

  // fetch POST || PATCH Data Table
  const { isLoading /* , isError, isSuccess */, mutate, } = useMutation(
    async (data: CarrierMaterialEntity) => {
      return fetch(
        `${CONFIG.URL}/api/carrier-material/${id ?? ''}`, {
          method     : id ? 'PATCH' : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify(data),
          credentials: 'include',
        }
      );
    }, {
      onSuccess: () => {
        setFields({
          name: '',
        });
        queryClient.invalidateQueries('carrierMaterials');
        navigate(-1);
      },
    }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate({
      name: fields.name,
    });
  };

  if (isLoading) {
    return <p>Zapisywanie zmian...</p>;
  }
  return (
    <div className='carrierMaterialForm'>
      <PageContainer>
        <HeaderSide title='Dodaj Typ Nośnika' icon={<FitbitOutlinedIcon />} />
        <Form handleSubmit={handleSubmit}>
          <InputT
            placeholder='Siatka / BBS'
            id='Nazwa Materiału'
            name='name'
            maxLength={36}
            minLength={3}
            value={fields.name || ''}
            handleChange={e => {
              handleChange(e);
            }}
            required
            focus
          />
          <div className='carrierMaterialAdd__buttonsContainer'>
            <Button type='submit' text={id ? 'Aktualizacja' : 'Zapisz'} />
            <Button
              className='button cancel'
              type='button'
              text='Anuluj'
              handleClick={() => 
                navigate(-1)}
            />
          </div>
        </Form>
      </PageContainer>
    </div>
  );
};
