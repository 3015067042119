/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import { useQuery, } from 'react-query';
import moment from 'moment';
import { CustomerEntity, CustomerResponse, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { queryClient, } from '../../..';
import { ArrowBackIosNewOutlinedIcon, CalendarMonthOutlinedIcon, EditOutlinedIcon, InsertDriveFileOutlinedIcon, Loader, PageContainer, PlaceOutlinedIcon, } from '../../../components';
import { CustomerInfo, } from './subPage/CustomerInfo';
import { CustomerContracts, } from './subPage/CustomerContracts';
import { CustomerMap, } from './subPage/CustomerMap';
import { CustomerPayment, } from './subPage/CustomerPayment';
import { CustomerImplement, } from './subPage/CustomerImplement';

export const Customer = () => {

  /* ------------ Przełącznik podstrony -------- */
  const [ page, setPage, ] = useState<string>('info');

  /* ------------ Nawigacja -------- */
  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego klienta -------- */
  const {
    isLoading:getIsLoading,
    isError: getIsError,
    data:customer,
  } = useQuery(
    [ 'customer', id, ], async (): Promise<CustomerEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/customer/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: CustomerResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('customers');
        return data;
      },
      enabled: !!id,
    }
  );

  /* ------------ Edycja Danych -------- */
  const handleEdit = (id: string|undefined) => {
    navigate(`/klient/edit/${id}`);
  };

  /* ------------ Powrót -------- */
  const handleBack = () => {
    navigate('/klient');
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (getIsLoading) return <Loader />;

  if (getIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  return (
    <div>
      <PageContainer>
        <div className='infoHeader'>
          <div className='infoHeader__up' >
            <div className='infoHeader__link'>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleBack()}
              >
                <ArrowBackIosNewOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleEdit(customer?.id)}
              >
                <EditOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
            </div>
          </div>
          <div className='infoHeader__bottom'>
            <h4 className='infoHeader__header' >{ customer?.name }</h4>
            <div className='infoHeader__location'>
              <div className='infoHeader__element'>
                <PlaceOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ customer?.city}</span>
              </div>
              <div className='infoHeader__element'>
                <InsertDriveFileOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ customer?.NIP }</span>
              </div>
              <div className='infoHeader__element'>
                <CalendarMonthOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ moment(customer?.registrationDate).format('YYYY-MM-DD')}</span>
              </div>
            </div>
            <div className='infoHeader__stats'>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>$500</h5>
                <p className='infoHeader__statsDesc'>Zysk</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>12</h5>
                <p className='infoHeader__statsDesc'>Umowy</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader infoHeader__statsHeader--red'>$5500</h5>
                <p className='infoHeader__statsDesc'>Wszystkie</p>
              </div>
            </div>
          </div>
          <nav className='infoNav'>
            <ul className='infoNav__list'>
              <li
                className={`infoNav__itemList ${page ==='info' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('info')}
              >Informacje</li>
              <li
                className={`infoNav__itemList ${page ==='umowy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('umowy')}
              >Umowy</li>
              <li
                className={`infoNav__itemList ${page ==='mapy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('mapy')}
              >Mapa</li>
              <li
                className={`infoNav__itemList ${page ==='realizacje' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('realizacje')}
              >Realizacje</li>
              <li
                className={`infoNav__itemList ${page ==='platnosc' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('platnosc')}
              >Płatności</li>
            </ul>
          </nav>
        </div>
        <div className='wrapper'>
          {customer && page === 'info' && <CustomerInfo customer={ customer} />}
          {id && page === 'umowy' && <CustomerContracts id={ id } />}
          {id && page ==='mapy' && <CustomerMap id={ id }/>}
          {id && page ==='realizacje' && <CustomerImplement id={ id }/>}
          {id && page ==='platnosc' && <CustomerPayment id={ id }/>}
        </div>
      </PageContainer>
    </div>
  );
};
