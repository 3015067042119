/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes, MouseEventHandler, } from 'react';
import './Button.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  type: 'submit' | 'reset' | 'button';
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({
  text,
  type = 'button',
  handleClick,
  ...rest
}: ButtonProps) => {
  return (
    <button type={type} className='button' onClick={handleClick} {...rest}>
      {text}
    </button>
  );
};
