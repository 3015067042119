/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, ReactNode, } from 'react';
import { useNavigate, } from 'react-router-dom';
import { AddOutlinedIcon, DeleteOutlineOutlinedIcon, EditOutlinedIcon, PaymentsOutlinedIcon, } from '../icons';

import './PaymentTable.css';
import { PaymentTableHeadRow, } from './PaymentTableHeadRow';

interface DataColumn<T> {
  name: string;
  data: keyof T | string; // changed to keyof T for better type safety
  mod?: (value: any, row: T) => ReactNode; // Modifier function for custom rendering
}

interface BasicTableProps<T> {
  dataColumns: DataColumn<T>[];
  dataRows?: T[];
  viewPopup: Dispatch<SetStateAction<boolean>>;
  popup: boolean;
  setIdDelete: Dispatch<SetStateAction<string>>;
  search: string;
}

export const PaymentTable = <T extends Record<string, any>>({
  dataRows,
  dataColumns,
  viewPopup,
  popup,
  setIdDelete,
  search,
}: BasicTableProps<T>) => {
  const navigate = useNavigate();

  // Function to access nested values
  const getNestedValue = (
    item: T, path: keyof T | string
  ): any => {
    return String(path).split('.')
      .reduce(
        (
          o, p
        ) => 
          o && o[ p ], item
      );
  };

  // Function to render a data cell with or without modification
  const renderDataCell = (
    item: T, column: DataColumn<T>
  ): ReactNode => {
    const value = getNestedValue(
      item, column.data
    );
    return column.mod ? column.mod(
      value, item
    ) : value;
  };

  /* ------------ Handlers for actions -------- */
  const handleInfo = (id: string) => 
    navigate(`${id}`);
  const handleEdit = (id: string) => 
    navigate(`edit/${id}`);
  const handleAddOne = (id: string) => 
    navigate(`plusOneCost/${id}`);
  const handleDelete = (row: T) => {
    setIdDelete(String(row.id));
    viewPopup(!popup);
  };

  /* ------------ Data filtering -------- */
  const filteredRows = search ? dataRows?.filter(item => {
    const searchData = dataColumns
      .map(column => {
        const value = getNestedValue(
          item, column.data
        );
        return (column.mod ? column.mod(
          value, item
        ) : value).toString().toLowerCase();
      })
      .join(' ');
    return searchData.includes(search.toLowerCase());
  }) : dataRows;

  return (
    <div className='wrapper'>
      <table className='basicTable'>
        <thead className='basicTable__head'>
          <PaymentTableHeadRow dataColumns={dataColumns} />
        </thead>
        <tbody className='basicTable__body'>
          {filteredRows?.map(row => 
            (
              <tr key={row.id} className='basicTable__bodyRow'>
                {dataColumns.map(column => 
                  (
                    <td key={String(column.data)} className='basicTable__dataRowHeader'>
                      {renderDataCell(
                        row, column
                      )}
                    </td>
                  ))}
                <td className='basicTable__dataRowHeaderEdit'>
                  <div className='basicTable__dataRowHeaderIcons'>
                    <button
                      type='button'
                      className='basicTable__dataRowHeaderButton'
                      onClick={() => 
                        handleInfo(row.id)}
                      title='Informacje'
                    >
                      <PaymentsOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                    </button>
                    <button
                      type='button'
                      className='basicTable__dataRowHeaderButton'
                      onClick={() => 
                        handleEdit(row.id)}
                      title='Edytuj'
                    >
                      <EditOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                    </button>
                    {row && (
                      <button
                        type='button'
                        className='basicTable__dataRowHeaderButton'
                        onClick={() => 
                          handleDelete(row)}
                        title='Usuń'
                      >
                        <DeleteOutlineOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                      </button>
                    )}
                    <button
                      type='button'
                      className='basicTable__dataRowHeaderButton'
                      onClick={() => 
                        handleAddOne(row.id)}
                      title='Dodaj jeden'
                    >
                      <AddOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
