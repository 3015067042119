import React from 'react';
import './carrierSub.css';
import { ContactsOutlinedIcon, PlaceOutlinedIcon, ReportOutlinedIcon, } from '../../../../components';
import { CarrierEntity, } from '../../../../types';

interface Props {
  carrier: CarrierEntity;
}

export const CarrierInfo = ({ carrier, }:Props) => {
  return (
    <div className='customerInfo'>
      <div className='customerInfo__left'>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <PlaceOutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} />
            <h5 className='customerInfo__header'>Dane Nośnika:</h5>
          </div>
          <div className='customerInfo__list'>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', }}>Miasto: { carrier.zipCode } { carrier.city }</p>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', }}>Ulica: { carrier.street } { carrier.streetNumber }</p>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', }}>Kierunek: { carrier.direction }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Długość geograficzna: { carrier.lon }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Szerokość geograficzna: {carrier.lat}</p>
          </div>
        </div>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <ContactsOutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }}/> <h5 className='customerInfo__header'>Parametry nośnika:</h5>
          </div>
          <div className='customerInfo__list'>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
              <p className='customerInfo__data' style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>szerokość: {carrier.width} m</p>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
                <p style={{ display: 'flex', justifyContent: 'center', transform: 'rotate(-90deg)', marginRight: '-120px', }}>wysokość: {carrier.height} m</p>
                <div style={{ width: '400px', height: '200px', backgroundColor: 'gold', borderRadius: '5px', }} />
              </div>
            </div>
            <p className='customerInfo__data' style={{ width: '48%', padding: '5px', }}>Typ: { carrier.carrierMaterial }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Oświetlenie: { carrier.isLight?<span style={{ backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px', }}>Jest</span>:<span style={{ backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px', }}>Brak</span> }</p>
          </div>
        </div>
      </div>
      <div className='customerInfo__right'>
        <div className='customerInfo__card'>
          <h5 className='customerInfo__header'>Uwagi:</h5>
          <div className='customerInfo__list'>
            {carrier.comment && <p className='customerInfo__data'>
              <ReportOutlinedIcon className='infoHeader__icon' style={{ border: '1px solid rgb(211, 230, 243)', color: 'rgb(18, 31, 67)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{ carrier.comment }</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
