import React, { useState, } from 'react';
import { useQuery, } from 'react-query';
import { CarrierEntity, CarriersResponse, DataColumn, } from '../../types';

import './Carriers.css';

import {
  AddButton,
  BasicTable,
  HeaderSide,
  Loader,
  PageContainer,
  PhotoSizeSelectActualOutlinedIcon,
  Popup,
  SearchBar,
} from '../../components';

import { CarrierDelete, } from './carrierDelete/CarrierDelete';

import { CONFIG, } from '../../utils/config';

/* ------------ Powiązanie nagłówka tabeli z danymi -------- */
const columns: DataColumn[] = [
  {
    name: 'Miasto',
    data: 'city',
  },
  {
    name: 'Ulica',
    data: 'street',
  },
  {
    name: 'Ulica Numer',
    data: 'streetNumber',
  },

  // {
  //   name: 'Właściciel',
  //   data: '',
  // },

];

export const Carriers = () => {

  /* ------------ Logika wyszukiwania klientów -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');

  // id usuwanego
  const [ idDelete, setIdDelete, ] = useState<string>('');

  // aktywny popup
  const [ popup, setPopup, ] = useState<boolean>(false);

  /* ------------ Pobieranie wszytkach klientów -------- */
  const {
    isLoading,
    isError,
    data: carriers,
  } = useQuery(
    'carriers', async (): Promise<CarrierEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/carrier`, {
          credentials: 'include',
        }
      );
      const { data, }: CarriersResponse = await res.json();
      return data;
    }
  );

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (isLoading) return <Loader />;

  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <main className='contractView'>
      <PageContainer>
        <HeaderSide
          title='Nośniki'
          icon={<PhotoSizeSelectActualOutlinedIcon />}
          button={<AddButton
            text='Dodaj nowy'
            action='new'
          />}
        />
        <SearchBar handleSearch={setSearch} search={search} />
        {carriers && (
          <BasicTable
            dataColumns={columns}
            dataRows={carriers}
            viewPopup={setPopup}
            popup={popup}
            setIdDelete={setIdDelete}
            search={search}
          />
        )}
      </PageContainer>
      {popup && idDelete && <Popup viewPopup={setPopup} >
        <CarrierDelete id={idDelete} viewPopup={setPopup}/>
      </Popup>}
    </main>
  );
};
