/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useEffect,
  useState,
} from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import { useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { queryClient, } from '../../..';

import {
  HeaderSide,
  PageContainer,
  Loader,
  ErrorMessage,
  Form,
  Fieldset,
  InputCheck,
  InputText,
  ArticleOutlinedIcon,
  Button,
  InputNumber,
  Textarea,
  InputRadioList,
  SearchBar,
} from '../../../components';

import { CarrierEntity, CarrierRequest, CarrierResponse, OwnerEntity, OwnersResponse, ServerErrors, } from '../../../types';

import './CarrierForm.css';
import { CONFIG, } from '../../../utils/config';
import { carrierSchema, } from './CarrierSchema';

export const CarrierForm = () => {

  /* ------------ Nawigacja -------- */

  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego nośnika -------- */
  
  const {
    isLoading:carrierIsLoading,
    isError: carrierIsError,
    data:carrier,
  } = useQuery(
    [ 'carrier', id, ], async (): Promise<CarrierEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/carrier/${id ?? ''}`, {
          credentials: 'include',
        }
      );
      const { data, }: CarrierResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('customers');
        if (data) {
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              setValue(
                key as keyof CarrierRequest, (data as any)[ key ]
              );
            }
          }
        }
        return data;
      },

      enabled: !!id,
    }
  );   

  /* ------------ Pobieranie wszytkach włascicieli do relacji -------- */
  const {
    isLoading:ownersIsLoading,
    isError:ownersIsError,
    data: owners,
  } = useQuery(
    'owners', async (): Promise<OwnerEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/owner`, {
          credentials: 'include',
        }
      );
      const { data, }: OwnersResponse = await res.json();
      return data;
    } 
  );

  /* ------------ Filtrowanie włascicieli -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');
  
  const filteredRows = search? owners?.filter(item => {
    const searchData = [
      item.name.toLowerCase(),
      item.nameOwner.toLowerCase(),
      item.NIP.toLowerCase(),
      item.street.toLowerCase(),
      item.zipCode.toLowerCase(),
      item.city.toLowerCase(),
    ].join(' ');

    const carriersData = item?.carriers?.map((carrier:CarrierEntity) => {
      return [
        carrier.city.toLowerCase(),
        carrier.zipCode.toLowerCase(),
        carrier.street.toLowerCase(),
      ].join(' ');
    }).join(' ');

    return (
      searchData.includes(search.toLowerCase()) ||
        carriersData?.includes(search.toLowerCase())
    );
  }): owners;

  /* ------ Przełącznik Edycja Relacji Widok relacji-Widok wybranego ------ */
  
  const [ isOpenOwner, setIsOpenOwner, ]=useState<boolean>(false);

  /* ------------ Stan i walidacja Formularza -------- */
   
  const { setValue,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, }, } = useForm<CarrierRequest>({
      resolver: yupResolver(carrierSchema),
      mode    : 'onBlur', // Walidacja na zdarzeniu onBlur
    });

  // console.log(watch());

  /* ------------ Walidacja Backend -------- */
  
  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);

  /* ------------ Wysyłanie Danych do Backendu -------- */
  
  const { mutate, isLoading, } = useMutation(
    async (data: CarrierRequest) => {
      const response = await fetch(
        `${CONFIG.URL}/api/carrier/${id ?? ''}`, {
          method     : id ? 'PATCH' : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ ...data, }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('carriers');
        queryClient.invalidateQueries([ 'carrier', id, ]);
        localStorage.removeItem('carrierValues');
        navigate(-1);
      },
      onSettled: newTodo => {
        queryClient.invalidateQueries([ 'carriers', newTodo.id, ]);
      },
    }
  );

  /* ------------ Zapisywanie danych do LocalStorage -------- */
  useEffect(
    () => {

      // Wczytywanie danych z LocalStorage przy inicjalizacji
      const savedData = localStorage.getItem('carrierValues');
      if (savedData) {
        const formValues = JSON.parse(savedData);
        for (const key in formValues) {
          setValue(
            key as keyof CarrierRequest, formValues[ key ]
          );
        }
      }
    }, [ setValue, ]
  );

  useEffect(
    () => {

      // Zapisywanie danych do LocalStorage przy zmianie wartości
      const subscription = watch(value => {
        localStorage.setItem(
          'carrierValues', JSON.stringify(value)
        );
      });
      return () => 
        subscription.unsubscribe();
    }, [ watch, ]
  );

  /* ------------ Akcja wysyłania formularza -------- */
  
  const onSubmit = (data: CarrierRequest) => {

    mutate(data);
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  
  if (carrierIsLoading) return <Loader />;
  if (ownersIsLoading) return <Loader />;

  if (carrierIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  if (ownersIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
   
  /* ------------ Renderowanie formularza -------- */
  
  return (
    <div className='carrierForm'>
      <PageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <HeaderSide title='Dodawanie Nośnika' icon={<ArticleOutlinedIcon />} />
          <Button
            className='button delete'
            type='button'
            text='Reset'
            style={{ marginRight: '20px', }}
            handleClick={() => {
              localStorage.removeItem('carrierValues');
              reset();
            }
            }
          />
        </div>
        {!isLoading && <div className='empty' />}
        {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
           )}
        <div className='wrapper'>
          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Fieldset legend='Nośnik aktywny:' direction='row' style={{ flex: 1, minWidth: '300px', gap: '0px', }}>
              <InputCheck
                id='Nośnik Aktywny'
                name='isActive'
                defaultChecked
                register={register}
              />
            </Fieldset>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <Fieldset legend='Miejsce Nośnika:' direction='row' style={{ flex: 1, minWidth: '300px', }} >
                <InputText
                  placeholder='Zabrze'
                  id='Miasto'
                  name='city'
                  maxLength={60}
                  required
                  errors={errors.city}
                  register={register}
                  value={watch('city')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='XX-XXX'
                  id='Kod Pocztowy'
                  name='zipCode'
                  maxLength={6}
                  required
                  errors={errors.zipCode}
                  register={register}
                  value={watch('zipCode')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='Wolności'
                  id='Ulica'
                  name='street'
                  minLength={3}
                  maxLength={60}
                  required
                  errors={errors.street}
                  register={register}
                  value={watch('street')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='19/15'
                  id='Numer ulicy'
                  name='streetNumber'
                  minLength={1}
                  maxLength={10}
                  required
                  errors={errors.streetNumber}
                  register={register}
                  value={watch('streetNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputNumber
                  placeholder='**,****'
                  id='Długość geo.[ - 90 - 90]'
                  name='lon'
                  min={-90}
                  max={90}
                  step={0.0000001}
                  errors={errors.lon}
                  register={register}
                  value={watch('lon')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputNumber
                  placeholder='**,****'
                  id='Szerokość geo. [0 - 180]'
                  name='lat'
                  min={-180}
                  max={180}
                  step={0.0000001}
                  errors={errors.lat}
                  register={register}
                  value={watch('lat')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='Katowice'
                  id='Kierunek'
                  name='direction'
                  maxLength={60}
                  errors={errors.direction}
                  register={register}
                  value={watch('direction')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
              </Fieldset>
              <Fieldset legend='Parametry nośnika:' direction='row' style={{ flex: 1, minWidth: '300px', alignContent: 'flex-start', }}>
                <InputNumber
                  placeholder='100 m'
                  name='width'
                  id='Szerokość [m]'
                  min={1}
                  max={1000}
                  step={1}
                  errors={errors.width}
                  register={register}
                  required
                  value={watch('width')}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputNumber
                  placeholder='100 m'
                  id='Wysokość [m]'
                  name='height'
                  min={1}
                  max={1000}
                  step={1}
                  errors={errors.height}
                  register={register}
                  required
                  value={watch('height')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputCheck
                  id='Oświetlenie'
                  name='isLight'
                  defaultChecked
                  register={register}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='Siatka'
                  id='Typ Nośnika'
                  name='carrierMaterial'
                  maxLength={28}
                  errors={errors.carrierMaterial}
                  required
                  register={register}
                  value={watch('carrierMaterial')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%', }}>
              <Fieldset
                legend='Właściciel:' direction='row'
                style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '0px 12px 24px 12px', }}
              >
                {id && carrier?.owner && !isOpenOwner ? <div>
                  <div className='customerInfo__list' style={{ padding: '40px', }}>
                    <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Nazwa: { carrier.owner.nameOwner }</p>
                    <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>NIP: { carrier.owner.NIP } { carrier.streetNumber }</p>
                    <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Miasto: {carrier.owner.zipCode} {carrier.owner.city}</p>
                    <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Ulica: { carrier.owner.street } { carrier.owner.streetNumber }</p>
                    <p className='customerInfo__data' style={{ width: '48%', }}>Tel: { carrier.owner.contactTel }</p>
                    <p className='customerInfo__data' style={{ width: '48%', }}>mail: {carrier.owner.contactEmail}</p>
                  </div>
                  <div style={{ width: '100%', }}>
                    <Button
                      type='button'
                      text='Zmień'
                      style={{ display: 'block', margin: '0 auto', }}
                      onClick={() => 
                        setIsOpenOwner(true)}/>
                  </div>
                </div> : <><div className='carrierMaterialDelete__buttons' style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', width: '600px', }}>
                  <SearchBar handleSearch={setSearch} search={search} />
                  <Button
                    type='button'
                    text=' + Dodaj właściciela'
                    className='button add'
                    onClick={() => 
                      navigate('/wlasciciel/new')}
                  />
                </div>
                <div style={{ minHeight: '200px', maxHeight: '20vh', overflow: 'auto', width: '600px', }}> {

                  [ ...(filteredRows ?? []), ].sort((
                    a, b
                  ) => {
                    const aChecked = carrier?.owner?.id === a.id;
                    const bChecked = carrier?.owner?.id === b.id;
                    return aChecked ? -1 : bChecked ? 1 : 0;
                  })
                    .map((owner: OwnerEntity) => {

                      // console.log(owner);
                      return (
                        <div key={owner.id}
                          style={{ display: 'flex', justifyContent: 'center', height: '40px', flexWrap: 'wrap', gap: '20px', }}
                        >
                          <InputRadioList
                            type='radio'
                            id={owner.id}
                            name='owner'
                            label={<div>{owner.name} -- {owner.NIP} -- {owner.city}</div>}
                            register={register}
                            value={owner.id}
                            defaultChecked={owner.id === carrier?.owner?.id}
                          />
                        </div>
                      );
                    })}
                </div></>}
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%', }}>
              <Fieldset legend='Uwagi:' direction='row' style={{ flex: 1, minWidth: '300px', }}>
                <Textarea
                  placeholder='....'
                  id='Uwagi'
                  name='comment'
                  maxLength={1000}
                  errors={errors.comment}
                  register={register}
                  required
                  value={watch('comment')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
            </div>
            <div className='carrierMaterialAdd__buttonsContainer' style={{ backgroundColor: 'white', padding: '24px', }}>
              <Button type='submit' text={id ? 'Aktualizacja' : 'Zapisz'} />
              <Button
                className='button cancel'
                type='button'
                text='Anuluj'
                handleClick={() => {
                  localStorage.removeItem('carrierValues');
                  navigate(-1);
                }}
              />
            </div>
          </Form>
        </div>
      </PageContainer>
    </div>
  );
};
