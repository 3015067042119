import React, { FormEvent, FormHTMLAttributes, ReactNode, } from 'react';
import './Form.css';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  handleSubmit?: (e: FormEvent) => void;
}

export const Form = ({ children, ...rest }: FormProps) => {
  return (
    <form className='form' {...rest}>
      {children}
    </form>
  );
};
