/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, } from 'react';

export const useIdleTimer = (timeout = 30 * 60 * 1000) => { // 30 minut
  useEffect(
    () => {
      const handleActivity = () => {
        localStorage.setItem(
          'lastActivity', Date.now().toString()
        );
      };

      window.addEventListener(
        'mousemove', handleActivity
      );
      window.addEventListener(
        'keypress', handleActivity
      );
      window.addEventListener(
        'scroll', handleActivity
      );

      return () => {

        window.removeEventListener(
          'mousemove', handleActivity
        );
        window.removeEventListener(
          'keypress', handleActivity
        );
        window.removeEventListener(
          'scroll', handleActivity
        );
      };
    }, [ timeout, ]
  );
};
