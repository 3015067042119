import React from 'react';
import './ownerSub.css';
import { AlternateEmailOutlinedIcon, ApartmentOutlinedIcon, LanguageOutlinedIcon, OutlinedFlagRoundedIcon, PortraitOutlinedIcon, RememberMeOutlinedIcon, } from '../../../../components';
import { OwnerEntity, } from '../../../../types';

interface Props {
  owner: OwnerEntity;
}

export const OwnerInfo = ({ owner, }:Props) => {
  return (
    <div className='customerInfo'>
      <div className='customerInfo__left'>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <ApartmentOutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} /> 
            <h5 className='customerInfo__header'>Dane Firm</h5>
          </div>          
          <div className='customerInfo__list'>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', }}>{ owner.nameOwner }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>NIP: { owner.NIP }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>REGON: {owner.REGON}</p>
            <p className='customerInfo__data'>IBAN: {owner.accountNumber}</p>
          </div>
        </div>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <OutlinedFlagRoundedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} />
            <h5 className='customerInfo__header'>Adres Firmy</h5>
          </div>
          <div className='customerInfo__list'>
            <p className='customerInfo__data'>ul. {owner.street} {owner.streetNumber}</p>
            <p className='customerInfo__data' style={{ width: '48%', }}> { owner.zipCode } { owner.city }</p>
          </div>
        </div>
      </div>
      <div className='customerInfo__right'>
        <div className='customerInfo__card'>
          <h5 className='customerInfo__header'>Dane Kontaktowe</h5>
          <div className='customerInfo__list'>
            {owner.contactPerson && <p className='customerInfo__data'>
              <PortraitOutlinedIcon className='infoHeader__icon' style={{ border: '1px solid rgb(211, 230, 243)', color: 'rgb(18, 31, 67)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{ owner.contactPerson }</p>}
            {owner.contactEmail && <a className='customerInfo__data' href={`mailto:${owner.contactEmail}`} target='_blank' rel='noreferrer'>
              <AlternateEmailOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(39, 206, 136)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{owner.contactEmail}</a>}
            {owner.contactTel && <a className='customerInfo__data' href={`tel:${owner.contactTel}`} target='_blank' rel='noreferrer'>
              <RememberMeOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(36, 153, 239)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{owner.contactTel}</a>}
            {owner.contactWww && <a className='customerInfo__data' href={owner.contactWww} target='_blank' rel='noreferrer'>
              <LanguageOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(255, 151, 119)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{owner.contactWww}</a>}
          </div>
        </div>
      </div>
    </div>
  );
};
