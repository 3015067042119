/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, } from 'react';
import { useQuery, } from 'react-query';
import moment from 'moment';
import { DataColumn, PaymentEntity, PaymentsResponse, } from '../../types';
import './payments.css';
import {
  AddButton,
  Button,
  HeaderSide,
  InputDate,
  Loader,
  PageContainer,
  PaymentTable,
  Popup,
  RequestQuoteOutlinedIcon,
  SearchBar,
  SearchOutlinedIcon,
} from '../../components';
import { CONFIG, } from '../../utils/config';
import { PaymentDelete, } from './paymentDelete/PaymentDelete';
import { PaymentsFilterContext, } from '../../contexts/payment/PaymentFilterContext';
import { FilterPaymentsContextType, } from '../../contexts/payment/PaymentFilterTypes';

/* ------------ Powiązanie nagłówka tabeli z danymi -------- */
// @ts-ignore
const columns: DataColumn<PaymentEntity>[] = [
  {
    name: 'Umowa',
    data: 'contractNumber',
  },
  {
    name: 'Numer Faktury',
    data: 'invoiceNumber',
    mod : (
      value: any, row: PaymentEntity
    ) => {
      return value ===''?<p style={{ color: 'red', }}>brak faktury</p>:value;
    },
  },
  {
    name: 'Data Faktury',
    data: 'invoiceDate',
  },
  { name: 'Wartość brutto',
    data: 'grossValue',
    mod : (
      value:any, row:PaymentEntity
    ) => 
        row.typePayment === 'koszt' ? <p style={{ color: 'red', display: 'flex', width: '50%', justifyContent: 'flex-end', }}>{(value * -1).toFixed(2)} zł</p>: <p style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', }}>{(Number(value)).toFixed(2)} zł</p>, },
  {
    name: 'Data Płatności',
    data: 'paymentDate',
  },
  {
    name: 'Status płatności',
    data: 'isPayment',
    mod : (
      value: boolean, row:any
    ) => 
      value ? <p className='table__pills table__pills--paid'>zapłacone</p> :<p className='table__pills table__pills--unpaid'>niezapłacone</p>,
  },
  {
    name: '',
    data: 'typePayment',
    mod : (
      value: string, row: any
    ) => 
      <p>{ value === 'koszt' ? 'właściciel' : 'klient' }</p>,
  },
  {
    name: 'Firma',
    data: 'owner.name',
  },
];

interface filterList{
isPayment: boolean | null
}
export const Payments = () => {

  /* ------------ Logika wyszukiwania klientów -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');

  // id usuwanego
  const [ idDelete, setIdDelete, ] = useState<string>('');

  // aktywny popup
  const [ popup, setPopup, ] = useState<boolean>(false);

  /* ------------ Pobieranie wszytkach klientów -------- */
  const {
    isLoading,
    isError,
    data: payments,
  } = useQuery(
    'payments', async (): Promise<PaymentEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment`, {
          credentials: 'include',
        }
      );
      const { data, }: PaymentsResponse = await res.json();
      return data;
    }
  );

  /* ------------ Filtrowanie -------- */

  const [ filterState, setFilterState, ] = useContext(PaymentsFilterContext) as FilterPaymentsContextType;

  const filteredRows = payments?.filter((payment:PaymentEntity) => {
    const paymentMoment = moment(payment.paymentDate);

    // Sprawdzenie isPayment
    const paymentMatch = filterState.isPayment === null 
    ? true 
    : filterState.isPayment === Boolean(payment.isPayment);

    // Sprawdzenie zakresu dat
    const isDateInRange = filterState.startDate && filterState.endDate
    ? paymentMoment.isBetween(
      filterState.startDate, filterState.endDate, undefined, '[]'
    ) // '[]' oznacza włącznie
    : true;

    return paymentMatch && isDateInRange;
  });

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (isLoading) return <Loader />;

  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <main className='contractView'>
      <PageContainer>
        <HeaderSide
          title='Płatności'
          icon={<RequestQuoteOutlinedIcon />}
          button={<AddButton text='Dodaj fakturę właściciela.' action='newCost'/>}
          button2={<AddButton text='Dodaj fakturę klienta.' action='newIncome'/>}
        />
        <div className='searchBar'>
          <div className='searchBar__inputContainer'>
            <SearchOutlinedIcon className='searchBar__icon' />
            <input
              type='search'
              placeholder='Szukaj'
              className='searchBar__input'
              value={filterState.search}
              onChange={e => 
                setFilterState({ ...filterState, search: e.target.value, })}
            />
          </div>
        </div>
        <div className='payment__filter'>
          <ul className='payment__filterStatus'>
            <li className={`payment__filterStatusItem ${filterState.isPayment === false ? 'active' : ''}`}
              onClick={() => 
                setFilterState({ ...filterState, isPayment: false, })}>
              <h6>Niezapłacone</h6>
              <small>{(payments?.filter((payment :PaymentEntity) => 
                Boolean(payment?.isPayment)===false))?.length }</small>
            </li>
            <li className={`payment__filterStatusItem ${filterState.isPayment === true ? 'active' : ''}`}
              onClick={() => 
                setFilterState({ ...filterState, isPayment: true, })}>
              <h6>Zapłacone</h6>
              <small>{(payments?.filter((payment :PaymentEntity) => 
                Boolean(payment?.isPayment)===true))?.length }</small>
            </li>
            <li className={`payment__filterStatusItem ${filterState.isPayment === null ? 'active' : ''}`}
              onClick={() => 
                setFilterState({ ...filterState, isPayment: null, })}
            >
              <h6>Wszystkie</h6>
              <small>{payments?.length}</small>
            </li>
          </ul>
          <div className='payment__filterDate'>
            <div className='searchBar__inputContainer' style={{ display: 'flex', }}>
              <label htmlFor='od' style={{ marginRight: '16px', }}>Od</label>
              <input type='date'
                id='od'
                className='searchBar__input'
                style={{ fontSize: '14px', }}
                value={moment(filterState.startDate).format('YYYY-MM-DD')}
                onChange={e =>
                  setFilterState({ ...filterState, startDate: e.target.value, })} />
            </div>
            <div className='searchBar__inputContainer' style={{ display: 'flex', }}>
              <label htmlFor='do' style={{ marginRight: '16px', }}>Do</label>
              <input
                type='date'
                id='do'
                className='searchBar__input'
                style={{ fontSize: '14px', }}
                value={moment(filterState.endDate).format('YYYY-MM-DD')}
                onChange={e =>
                  setFilterState({ ...filterState, endDate: e.target.value, })} />
            </div>
            <Button
              type='button'
              style={{ padding: '12px 10px', }}
              onClick={() => 
                setFilterState({
                  ...filterState,
                  startDate: moment().startOf('month')
                    .format(), // Ustawia pierwszy dzień bieżącego miesiąca
                  endDate  : moment().endOf('month')
                    .format(), // Ustawia ostatni dzień bieżącego miesiąca
                })} text='Bieżący miesiąc'/>
          </div>
        </div>
        {payments && (
          <PaymentTable
            dataColumns={columns}
            dataRows={filteredRows}
            viewPopup={setPopup}
            popup={popup}
            setIdDelete={setIdDelete}
            search={filterState.search}
          />
        )}
      </PageContainer>
      {popup && idDelete && <Popup viewPopup={setPopup} >
        <PaymentDelete id={idDelete} viewPopup={setPopup}/>
      </Popup>}
    </main>
  );
};
