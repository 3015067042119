/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link, useLocation, useNavigate, } from 'react-router-dom';

import './Sidebar.css';
import { useMutation, } from 'react-query';
import {
  KeyboardTabOutlinedIcon,
  PersonAddAlt1OutlinedIcon,
  PhotoSizeSelectActualOutlinedIcon,
  PersonRemoveOutlinedIcon,
  SettingsApplicationsOutlinedIcon,
  AccountBalanceOutlinedIcon,
  RequestQuoteOutlinedIcon,
} from '../icons';
import { BreakLine, } from '../breakLine/BreakLine';
import { CONFIG, } from '../../utils/config';

export const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const { mutate, } = useMutation(
    async () => {
      const response = await fetch(
        `${CONFIG.URL}/api/auth/logout`, {
          method     : 'POST',
          credentials: 'include',
        }
      );

      return response.json();
    }, {
      onSettled: () => {

        localStorage.removeItem('login');

        // Zapisywanie ścieżki przed wylogowaniem
        localStorage.setItem(
          'preLogoutPath', location.pathname
        );
        navigate('/login');
      },
    }
  );

  /* ------------ Renderowanie  -------- */
  return (
    <div className='sidebar'>
      <div className='sidebar__top'>
        <span className='sidebar__logo'>
          <Link className='sidebar__link' to='/'>
            Fun Art Media
          </Link>
        </span>
      </div>
      <BreakLine />
      <div className='sidebar__center'>
        <ul className='sidebar__list'>
          <p className='sidebar__title'>Start</p>
          <li className='sidebar__item'>
            <Link to='/' className='sidebar__link'>
              <AccountBalanceOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Strona główna</span>
            </Link>
          </li>
          <p className='sidebar__title'>Finanse</p>
          <li className='sidebar__item'>
            <Link to='/platnosci' className='sidebar__link'>
              <RequestQuoteOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Płatności</span>
            </Link>
          </li>
          <p className='sidebar__title'>Dokumenty</p>
          {/* <li className='sidebar__item'>
            <Link to='/umowy-klient' className='sidebar__link'>
              <ArticleOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Umowy Klienci</span>
            </Link>
          </li> */}
          {/* <li className='sidebar__item'>
            <Link to='/umowy-wlasciciele' className='sidebar__link'>
              <ArticleOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Umowy Właściciele</span>
            </Link>
          </li> */}
          <p className='sidebar__title'>Raporty</p>
          <p className='sidebar__title'>Firmy</p>
          <li className='sidebar__item'>
            <Link to='/klient' className='sidebar__link'>
              <PersonAddAlt1OutlinedIcon className='sidebar__icon' />
              <span className='sidebar__name'>Klienci</span>
            </Link>
          </li>
          <li className='sidebar__item'>
            <Link to='/wlasciciel' className='sidebar__link'>
              <PersonRemoveOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Właściciele</span>
            </Link>
          </li>
          <p className='sidebar__title'>Oferta</p>
          <li className='sidebar__item'>
            <Link to='/nosniki' className='sidebar__link'>
              < PhotoSizeSelectActualOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Nośniki</span>
            </Link>
          </li>
          {/* <li className='sidebar__item'>
            <Link to='/typy-nosnikow' className='sidebar__link'>
              <FitbitOutlinedIcon className='sidebar__icon'/>
              <span className='sidebar__name'>Typ Nośnika</span>
            </Link>
          </li> */}
          <p className='sidebar__title'>Użytkownik</p>
          <li className='sidebar__item'>
            <Link to='/ustawienia' className='sidebar__link'>
              <SettingsApplicationsOutlinedIcon className='sidebar__icon' />
              <span className='sidebar__name'>Ustawienia</span>
            </Link>
          </li>
          <li className='sidebar__item' onClick={() => 
            mutate()}>
            <Link to='/ustawienia' className='sidebar__link'>
              <KeyboardTabOutlinedIcon className='sidebar__icon' />
              <span className='sidebar__name'>Wyloguj</span>
            </Link>
          </li>
        </ul>
      </div>
      <BreakLine />
    </div>
  );
};
