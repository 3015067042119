import React, { InputHTMLAttributes, } from 'react';
import moment from 'moment';

interface Props extends InputHTMLAttributes<HTMLSpanElement>{
date: string
}

export const IconInListCost = ({ date, ...rest }:Props) => {

  // Format dzisiejszej daty
  const today = moment().format('YYYY-MM-DD');

  // Sprawdź, czy podana data jest mniejsza lub równa dzisiejszej
  const isTodayOrEarlier = moment(date).isSameOrBefore(today);

  // Wyświetl odpowiednią ikonę
  return (
    <div>
      {isTodayOrEarlier ? (
        <span {...rest}>🔥</span> // Ikona ognia
      ) : (
        <span {...rest}>⏰</span> // Ikona budzika
      )}
    </div>
  );
};
