/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, ReactNode, SetStateAction, MouseEvent, } from 'react';
import './Popup.css';

interface PopupProps {
  viewPopup: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

export const Popup = ({ viewPopup, children, }: PopupProps) => {

  const handleOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) viewPopup(false);
  };
  return (
    <div className='popup' onClick={handleOutsideClick}>
      <div className='popup__container'>{children}</div>
    </div>
  );
};
