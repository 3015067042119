/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import React, { InputHTMLAttributes, useEffect, useRef, } from 'react';
import './Input.css';
import { FieldError, } from 'react-hook-form';
import { ErrorInput, } from '../errors/ErrorInput';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  maxLength: number;
  name: string;
  id: string;
  placeholder: string;
  focus?: boolean;
  register: any;
  errors: FieldError | undefined;
}

export const InputPassword = ({
  name,
  id,
  maxLength,
  placeholder,
  focus,
  required,
  register,
  errors,
  ...rest
}: InputProps) => {

  // Ref
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => {
          focus && inputRef?.current?.focus();
        }, 200
      );
    }, [ focus, ]
  );
  return (
    <div className='input'>
      <input
        ref={inputRef}
        className={`input__data ${required && 'required'}`}
        type='password'
        name={name}
        id={id}
        autoComplete='off'
        tabIndex={0}
        maxLength={maxLength}
        placeholder={placeholder}
        required
        {...register(name)}
        {...rest}
      />
      <label className={`input__label ${required && 'required'}`} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>
      <ErrorInput errors={errors} />
    </div>
  );
};
