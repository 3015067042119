/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import { useQuery, } from 'react-query';
import { CarrierEntity, CarrierResponse, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { queryClient, } from '../../..';
import { EditOutlinedIcon, Loader, PageContainer, PlaceOutlinedIcon, ReplyOutlinedIcon, } from '../../../components';
import { CarrierInfo, } from './subPage/CarrierInfo';
import { CarrierContracts, } from './subPage/CarrierContracts';
import { CarrierMap, } from './subPage/CarrierMap';
import { CarrierImplement, } from './subPage/CarrierImplement';
import { CarrierPayment, } from './subPage/CarrierPayment';
import { CarrierOwner, } from './subPage/CarrierOwner';

export const Carrier = () => {

  /* ------------ Przełącznik podstrony -------- */
  const [ page, setPage, ] = useState<string>('info');

  /* ------------ Nawigacja -------- */
  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego klienta -------- */
  const {
    isLoading:getIsLoading,
    isError: getIsError,
    data:carrier,
  } = useQuery(
    [ 'carrier', id, ], async (): Promise<CarrierEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/carrier/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: CarrierResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('carriers');
        return data;
      },
      enabled: !!id,
    }
  );

  /* ------------ Edycja Danych -------- */
  const handleEdit = (id: string|undefined) => {
    navigate(`/nosniki/edit/${id}`);
  };

  /* ------------ Powrót -------- */
  const handleBack = () => {
    navigate('/nosniki');
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (getIsLoading) return <Loader />;

  if (getIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  return (
    <div>
      <PageContainer>
        <div className='infoHeader'>
          <div className='infoHeader__up' >
            <div className='infoHeader__link'>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleBack()}
              >
                <ReplyOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
              <button
                type='button'
                className='basicTable__dataRowHeaderButton'
                onClick={() => 
                  handleEdit(carrier?.id)}
              >
                <EditOutlinedIcon className='basicTable__dataRowHeaderIcon' />
              </button>
            </div>
          </div>
          <div className='infoHeader__bottom'>
            <h4 className='infoHeader__header' >Nośnik</h4>
            <div className='infoHeader__location'>
              <div className='infoHeader__element'>
                <PlaceOutlinedIcon className='infoHeader__icon' />
                <span className='infoHeader__info'>{ carrier?.city} { carrier?.street} { carrier?.streetNumber}</span>
              </div>
            </div>
            <div className='infoHeader__stats'>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>$500</h5>
                <p className='infoHeader__statsDesc'>Zysk</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader'>12</h5>
                <p className='infoHeader__statsDesc'>Umowy</p>
              </div>
              <div className='infoHeader__statsCard'>
                <h5 className='infoHeader__statsHeader infoHeader__statsHeader--red'>$5500</h5>
                <p className='infoHeader__statsDesc'>Wszystkie</p>
              </div>
            </div>
          </div>
          <nav className='infoNav'>
            <ul className='infoNav__list'>
              <li
                className={`infoNav__itemList ${page ==='info' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('info')}
              >Informacje</li>
              <li
                className={`infoNav__itemList ${page ==='owner' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('owner')}
              >Właściciel</li>
              <li
                className={`infoNav__itemList ${page ==='umowy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('umowy')}
              >Umowy</li>
              <li
                className={`infoNav__itemList ${page ==='mapy' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('mapy')}
              >Mapa</li>
              <li
                className={`infoNav__itemList ${page ==='realizacje' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('realizacje')}
              >Realizacje</li>
              <li
                className={`infoNav__itemList ${page ==='platnosc' && 'infoNav__itemList--active'}`}
                onClick={() => 
                  setPage('platnosc')}
              >Płatności</li>
            </ul>
          </nav>
        </div>
        <div className='wrapper'>
          {carrier && page === 'info' && <CarrierInfo carrier={ carrier } />}
          {carrier && page === 'owner' && <CarrierOwner carrier={ carrier } />}
          {id && page === 'umowy' && <CarrierContracts id={ id } />}
          {id && page ==='mapy' && <CarrierMap id={ id }/>}
          {id && page ==='realizacje' && <CarrierImplement id={ id }/>}
          {id && page ==='platnosc' && <CarrierPayment id={ id }/>}
        </div>
      </PageContainer>
    </div>
  );
};
