/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import './dashboardView.css';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { useQuery, } from 'react-query';
import { Link, } from 'react-router-dom';
import { CONFIG, } from '../../utils/config';
import { ArrowUpwardOutlinedIcon, HorizontalRuleOutlinedIcon, IconInListCost, Loader, } from '../../components';
import { PaymentSumCostResponse, } from '../../types';

export const Dashboard = () => {

  /* ------------ Pobieranie danych do sumy umowy-nośniki -------- */
  const {
    isLoading :isSumCostLoading,
    data: costSum,

  } = useQuery(
    'paymentCostSum', async ():Promise<PaymentSumCostResponse> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment/stat/costSum`, {
          credentials: 'include',
        }
      );
      const { data, } = await res.json();
      return data;
    }
  );

  /* ------------ Pobieranie danych do listy faktur umowy-nośniki -------- */
  const {
    isLoading :isListCostLoading,
    data: costList,

  } = useQuery(
    'paymentCostList', async () => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment/stat/costList`, {
          credentials: 'include',
        }
      );
      const { data, } = await res.json();
      return data;
    }
  );

  /* ------------ Pobieranie danych do wykresu kosztów -------- */
  const {
    isLoading :isChartCostLoading,
    data: costChart,
  } = useQuery(
    'paymentCostChart', async () => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment/stat/costChart`, {
          credentials: 'include',
        }
      );
      const { data, } = await res.json();
      return data;
    }
  );
  return <div className='dashboard'>
    <div className='box box1'>
      <h5 className='box1__header'>Lista faktur umowy-nośniki:</h5>
      <div className='box1__container'>
        {/* @ts-ignore */}
        {isListCostLoading ? <Loader /> :<ul className='box1__list'>{costList.map(costEl =>
          <li key={costEl.id} className='box1__listElement'>
            <span className='box1__listText box1__listText--name'>{costEl.owner.name}</span>
            <span className='box1__listText box1__listText--date'>{costEl.paymentDate}</span>
            <span className='box1__listText box1__listText--data'>{costEl.grossValue} zł</span>
            <IconInListCost date={costEl.paymentDate } className='.box1__listIcon'/>
          </li>)}
        </ul>}
      </div>
      <div className='box1__footer'>
        <Link to='/platnosci' className='sidebar__link'>
          <span className='sidebar__name'>wszystkie płatności ...</span>
        </Link></div>
    </div>
    <div className='box box2'><h5>Zysk miesiąc</h5></div>
    <div className='box box3'>
      <h5>X</h5>
    </div>
    <div className='box box4'>
      <h5>Lista Faktur umowy-klienci:</h5>
    </div>
    <div className='box box5'>
      <h5>Nowe Umowy</h5>
    </div>
    <div className='box box6'>
      <h5>Aktywne Umowy</h5>
    </div>
    <div className='box box7'>
      <h5>Koszty</h5>
      <div className='box7__container'>
        {isChartCostLoading ? <div style={{ width: '100%', height: '100%', }}><Loader /></div> : <ResponsiveContainer width='100%' height='100%'>
          {/* @ts-ignore */}
          <BarChart data={costChart} margin={{ top: 20, right: 30, left: 20, bottom: 5, }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='monthAndYear' />
            <YAxis />
            <Tooltip />
            <Bar dataKey='total' fill='#8884d8' />
          </BarChart>
        </ResponsiveContainer>}
      </div>
    </div>
    <div className='box box8'>
      <h5 className='box8__header'>Suma umowy-nośniki:</h5>
      {isSumCostLoading ? <div style={{ width: '100%', height: '100%', }}><Loader /></div> : <div>
        <h3 className='box8__subHeader'>{costSum?.currentMonthCost} zł</h3>
        <div className='box8__percentContainer'>
          <div
            className={`box8__percentIcon ${Number(costSum?.difference) > 0 ? 'positive' : 'negative'}`}
          >
            {Number(costSum?.difference) === 0 ? <HorizontalRuleOutlinedIcon className='box8__percentIcoEl'/> :<ArrowUpwardOutlinedIcon
              className={`box8__percentIcoEl ${Number(costSum?.difference) > 0 ? 'positive' : 'negative'}`}
            />}
          </div>
          <div
            className='box8__percentValue'>
            {Number(costSum?.difference) === 0 ?<span>{costSum?.difference} %</span> : <span className={`box8__percentValue ${Number(costSum?.difference) > 0 ? 'positive' : 'negative'}`}>{costSum?.difference} %</span>}
          </div>
        </div>
      </div>}
    </div>
    <div className='box box9'>
      <h5>Suma umowy-klienci:</h5>
    </div>
  </div>;
};
