/* eslint-disable no-unused-expressions */
import React, { InputHTMLAttributes, } from 'react';
import './ContractNumber.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
}

export const ContractNumber = ({ value, }: InputProps) => {
  return (
    <div className='contractNumber'>
      <p>{value}</p>
    </div>
  );
};
