import React from 'react';
import './errors.css';
import { FieldError, } from 'react-hook-form';
import { ServerErrors, } from '../../types';

interface InputErrorProps {
  errors?: FieldError;
  serverErrors?: ServerErrors | null;
  errorName?: string;
  validate?: string;
}

export const ErrorInput = ({ errors, serverErrors, errorName, validate, }: InputErrorProps) => {

  // Sprawdzanie, czy wystąpił błąd serwera dla danego pola
  const serverErrorForField = serverErrors?.property === errorName ? serverErrors?.constraints?.map(el => 
    el[ validate as keyof typeof el ]) : null;

  // Jeśli wystąpił błąd serwera, nie wyświetlaj błędu walidacji
  const validationError = serverErrorForField ? null : errors?.message;

  return (
    <div>
      {(serverErrorForField || validationError) && (
        <p className='errorInput'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='errorInput__icon'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
            />
          </svg>
          <span className='errorInput__message'>{serverErrorForField || validationError}</span>
        </p>
      )}
    </div>
  );
};
