/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, } from 'react';
import { useNavigate, } from 'react-router-dom';
import { DeleteOutlineOutlinedIcon, EditOutlinedIcon, MoreHorizOutlinedIcon, } from '../icons';

import './BasicTable.css';
import { BasicTableHeadRow, } from './BasicTableHeadRow';

interface DataColumn {
  name: string;
  data: string;
}

interface BasicTableProps<T> {
  dataColumns: DataColumn[];
  dataRows: T[];
  viewPopup: Dispatch<SetStateAction<boolean>>;
  popup: boolean;
  setIdDelete: Dispatch<SetStateAction<string>>;
  search: string;
}

export const BasicTable = <T extends Record<string, any>>({
  dataRows,
  dataColumns,
  viewPopup,
  popup,
  setIdDelete,
  search,
}: BasicTableProps<T>) => {
  const navigate = useNavigate();

  /* ------------ Informacja jednego -------- */
  const handleInfo = (id: string) => {
    navigate(`${id}`);
  };

  /* ------------ Edycja Danych -------- */
  const handleEdit = (id: string) => {
    navigate(`edit/${id}`);
  };

  /* ------------ Usuwanie Danych -------- */
  const handleDelete = (row: T) => {
    setIdDelete(String(row.id));
    viewPopup(!popup);
  };

  /* ------------ filtrowanie danych Danych -------- */
  const filteredRows = search? dataRows?.filter(item => {
    const searchData = dataColumns
      .map(column => 
        item[ column.data as keyof T ].toLowerCase())
      .join(' ');
    return searchData.includes(search.toLowerCase());
  }): dataRows;

  return (
    <div className='wrapper'>
      <table className='basicTable'>
        <thead className='basicTable__head'>
          <BasicTableHeadRow dataColumns={dataColumns} />
        </thead>
        <tbody className='basicTable__body'>
          {filteredRows?.map(row => 
            (
              <tr key={row.id} className='basicTable__bodyRow'>
                {dataColumns.map(column => 
                  (
                    <td key={column.data} className='basicTable__dataRowHeader'>
                      {row[ column.data as keyof T ]}
                    </td>
                  ))}
                <td className='basicTable__dataRowHeaderEdit'>
                  <div className='basicTable__dataRowHeaderIcons'>
                    <button
                      type='button'
                      className='basicTable__dataRowHeaderButton'
                      onClick={() => 
                        handleInfo(row.id)}
                    >
                      <MoreHorizOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                    </button>
                    <button
                      type='button'
                      className='basicTable__dataRowHeaderButton'
                      onClick={() => 
                        handleEdit(row.id)}
                    >
                      <EditOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                    </button>
                    {row && (
                      <button
                        type='button'
                        className='basicTable__dataRowHeaderButton'
                        onClick={() => 
                          handleDelete(row)}
                      >
                        <DeleteOutlineOutlinedIcon className='basicTable__dataRowHeaderIcon' />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
