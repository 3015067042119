/* eslint-disable no-return-await */
import React, { useState, } from 'react';

import { useNavigate, useParams, } from 'react-router-dom';
import { useMutation, useQuery, } from 'react-query';
import { ArrowBackIosNewOutlinedIcon, BreakLine, Button, ContentCopyOutlinedIcon, ErrorMessage, Loader, } from '../../../components';
import { PaymentEntity, PaymentResponse, PaymentStatus, ServerErrors, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { queryClient, } from '../../..';

// Add
import './paymentpay.css';

export const PaymentPay = () => {

  /* ------------ Nawigacja -------- */
  const { id, } = useParams();
  const navigate = useNavigate();
   
  /* ------------ Pobieranie jednego właściciela -------- */
  const {
    isLoading,
    isError,
    data:payment,
  } = useQuery(
    [ 'payment', id, ], async (): Promise<PaymentEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: PaymentResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('payments');
        return data;
      },
      enabled: !!id,
    }
  );

  /* ------------ Walidacja Backend -------- */

  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);

  // console.log(serverErrors);

  /* ------------ Wysyłanie Danych do Backendu -------- */

  const { mutate, isLoading:isLoadingSave, } = useMutation(
    async (data: PaymentStatus) => {
      const response = await fetch(
        `${CONFIG.URL}/api/payment/status/${id}`, {
          method     : 'PATCH',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ ...data, }),
          credentials: 'include',
        }
      );

      // console.log(response);
      if (!response.ok || response.status === 400) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('payments');
        queryClient.invalidateQueries([ 'payment', id, ]);
        navigate(-1);
      },
      onSettled: newTodo => {
        queryClient.invalidateQueries([ 'payments', newTodo.id, ]);
      },
    }
  );

  /* ------------ Akcja wysyłania formularza -------- */

  const onSubmit = () => {
    mutate({ isPayment: true, });
  };

  /* ------------ Powrót -------- */
  const handleBack = () => {
    navigate('/platnosci');
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (isLoading) return <Loader />;

  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return <div className='paymentPay'>
    <div className='box paymentPay__header'>
      <button
        type='button'
        className='basicTable__dataRowHeaderButton'
        onClick={() => 
          handleBack()}
      >
        <ArrowBackIosNewOutlinedIcon className='basicTable__dataRowHeaderIcon' />
      </button>
      <h4 className='infoHeader__header' style={{ marginBottom: '12px', }} >Płatność dla właściciela</h4>
      {payment?.isPayment ? <h5 style={{ color: '#fff', backgroundColor: 'red', width: '20%', margin: '0 auto', textAlign: 'center', padding: '12px', borderRadius: '4px', fontSize: '14px', }}>Faktura opłacona</h5>:null}
      {!isLoadingSave && <div className='empty' />}
      {isLoadingSave ? (
          <Loader />
        ) : (
          serverErrors?.property === 'ValidationError' && (
            <ErrorMessage serverError={serverErrors} />
          )
        )}
    </div>
    <div className='paymentPay__container'>
      <div className='box paymentBox1'>
        <h5 className='box__header'>Dane do płatności</h5>
        {payment?.invoiceDate &&
              <div className='box__item'>Data faktury
                <span>{`${payment?.invoiceDate}`}</span>
              </div>}
        <div className='box__item'>Data płatności
          <span>{`${payment?.paymentDate}`}</span>
        </div>
        <BreakLine />
        <div className='box__item' style={{ marginTop: '12px', }}>
          <span style={{ width: '26%', }}>Forma płatności:</span>
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '70%', fontWeight: '600', paddingRight: '24px', }}>{payment?.formPayment === 'gotowka'?'Gotówka':'Przelew'}</span>
          <div style={{ cursor: 'pointer', width: '4%', }} />
        </div>
        {payment?.formPayment === 'przelew' && <div className='box__item' style={{ marginTop: '12px', }}>
          <span style={{ width: '26%', }}>Numer rachunku:</span>
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '70%', fontWeight: '600', paddingRight: '24px', }}>{payment?.owner?.accountNumber}</span>
          <ContentCopyOutlinedIcon
            className='basicTable__dataRowHeaderIcon'
            titleAccess='Skopiuj numer rachunku'
            style={{ cursor: 'pointer', width: '4%', }}
            onClick={() =>
              payment?.owner?.accountNumber && navigator.clipboard.writeText(payment?.owner?.accountNumber)}/>
        </div>}
        { payment?.formPayment === 'przelew' && <div className='box__item' style={{ marginTop: '12px', }}>
          <span style={{ width: '26%', }}>Nazwa i adres odbiorcy:</span>
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '70%', fontWeight: '600', paddingRight: '24px', }}>{payment?.owner?.nameOwner} {payment?.owner?.zipCode} {payment?.owner?.city} ul. {payment?.owner?.street} {payment?.owner?.streetNumber}</span>
          <ContentCopyOutlinedIcon
            className='basicTable__dataRowHeaderIcon'
            titleAccess='Skopiuj nazwa i adres odbiorcy'
            style={{ cursor: 'pointer', width: '4%', }}
            onClick={() =>
              navigator.clipboard.writeText(`${payment?.owner?.nameOwner}  ${payment?.owner?.zipCode} ${payment?.owner?.city} ul. ${payment?.owner?.street} ${payment?.owner?.streetNumber}`)} />
        </div>}
        {payment?.formPayment === 'przelew' && <div className='box__item' style={{ marginTop: '12px', }}>
          <span style={{ width: '26%', }}>Kwota:</span>
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '70%', fontWeight: '600', paddingRight: '24px', }}>{payment?.grossValue} zł</span>
          <ContentCopyOutlinedIcon
            className='basicTable__dataRowHeaderIcon'
            titleAccess='Skopiuj wartość płatności'
            style={{ cursor: 'pointer', width: '4%', }}
            onClick={() =>
              navigator.clipboard.writeText(`${payment?.grossValue && String((payment?.grossValue)).replace(
                '.', ','
              )}`)} />
        </div>}
        {payment?.formPayment === 'przelew' && <div className='box__item' style={{ marginTop: '12px', }}>
          <span style={{ width: '26%', }}>Tytuł:</span>
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '70%', fontWeight: '600', paddingRight: '24px', }}>Numer umowy {payment?.contractNumber} Faktura numer {payment?.invoiceNumber}</span>
          <ContentCopyOutlinedIcon
            className='basicTable__dataRowHeaderIcon'
            titleAccess='Skopiuj tytuł płatności'
            style={{ cursor: 'pointer', width: '4%', }}
            onClick={() =>
              navigator.clipboard.writeText(`Numer umowy: ${payment?.contractNumber}\nNumer faktury: ${payment?.invoiceNumber}`)} />
        </div>}
      </div>
      <div className='box paymentBox2'>
        <h5 className='box__header'>Klient</h5>
        <div className='box__item'><span>{payment?.paymentThing}</span></div>
      </div>
      <div className='box paymentBox3'>
        <h5 className='box__header'>Wartości do zapłaty</h5>
        <div className='box__item'>Wartość netto <span>{payment?.netValue} zł</span></div>
        <div className='box__item'><span>Vat {payment?.tax}%</span> <span>{`${(Number(payment?.netValue) * Number(payment?.tax) / 100).toFixed(2)}`} zł</span></div>
        <div className='box__item'>Wartość brutto <span>{payment?.grossValue} zł</span></div>
        <BreakLine/>
        {!payment?.isPayment && <Button
          text='Zapłacone'
          type='submit'
          style={{ width: '100%', marginTop: '24px', }}
          onClick={() =>
            onSubmit()}
        />}
      </div>
    </div>
  </div>;
};
