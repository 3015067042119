/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { InputHTMLAttributes, } from 'react';
import './Input.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {

  // register: any;
  name?: string
  label: string;
  id?: string;
  defaultChecked?: boolean
}

export const InputCheckList = ({

  // register,
  name,
  id,
  label,
  ...rest
}: InputProps) => {
  return (
    <div className='checkInput'>
      <input
        type='checkbox'
        className='checkbox'
        id={id}
        name={name}

        // {...register(name)}
        {...rest}
      />
      
      <label
        className='inputCheckbox__label'
        htmlFor={id}
      >{label}</label>
    </div>
  );
};
