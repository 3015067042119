/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SelectHTMLAttributes, } from 'react';

interface SelectProps<T> extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  name: string;
  register: any;
  value?: string;
  disabled?: boolean;
  options: T; // Obiekt z opcjami
}

export const InputSelect = <T extends Record<string, string>>({ id, name, options, value, required, register, disabled, ...rest }: SelectProps<T>) => {

  const hasValue = value != null && value !== '';
  return (
    <div className='input' style={{ cursor: 'pointer', }}>
      <select
        name={name}
        id={id}
        tabIndex={0}
        value={value}
        required={required}
        style={{ cursor: 'pointer', }}
        className={`input__data ${required && 'required'}`}
        {...register(name)}
        {...rest}>
        {Object.entries(options).map(([ valueEl, labelEl, ]) => 
          (
            <option key={valueEl} value={valueEl}>{labelEl}</option>
          ))}
      </select>
      <label className={`input__label ${hasValue || disabled ? 'has-value' : ''}`} style={{ backgroundColor: disabled ? 'transparent' : '', color: disabled ? 'transparent' :'', cursor: 'pointer', }} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>  
    </div>
  );
};