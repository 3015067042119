/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useEffect,
  useState,
} from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import { useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import moment from 'moment';
import { queryClient, } from '../../..';

import {
  HeaderSide,
  PageContainer,
  Loader,
  ErrorMessage,
  Form,
  Fieldset,
  InputText,
  Button,
  InputNumber,
  Textarea,
  InputDate,
  RequestQuoteOutlinedIcon,
  InputSelect,
} from '../../../components';

import { PaymentEntity, PaymentRequest, ServerErrors, PaymentResponse, } from '../../../types';

import './paymentForm.css';
import { CONFIG, } from '../../../utils/config';
import { paymentSchema, } from './paymentSchema';

export const PaymentAddOneForm = React.memo(() => {

  /* ------------ Nawigacja -------- */

  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego nośnika -------- */
  
  const {
    isLoading:paymentIsLoading,
    isError: paymentIsError,
    data:payment,
  } = useQuery(
    [ 'paymentAddOne', id, ], async (): Promise<PaymentEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/payment/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: PaymentResponse = await res.json();
      return data;
    }, {
      refetchOnWindowFocus: false,
      enabled             : !!id,
      onSuccess           : async data => {
        await queryClient.invalidateQueries('payments');

        // Formatowanie danych z backendu tylko tych co sa w danym momencie w formularzu
        if (data) {
          const resStartDataToForm = {
            typePayment   : data.typePayment,
            formPayment   : data.formPayment,
            wasPaymentDate: data.wasPaymentDate,
            isPayment     : false,
            contractNumber: data.contractNumber,
            invoiceNumber : '',
            invoiceDate   : data.invoiceDate,
            paymentDate   : data.paymentDate,
            paymentThing  : data.paymentThing,
            netValue      : data.netValue,
            tax           : data.tax,
            grossValue    : data.grossValue,
            owner         : data?.owner?.id,
          };

          // Stan poczatkowy formularza
          for (const klucz in resStartDataToForm) {
            if (resStartDataToForm.hasOwnProperty(klucz)) {
              setValue(

                // @ts-ignore
                klucz, resStartDataToForm[ klucz ]
              );
            }
          }
        }
        return data;
      },
    }
  );   

  /* ------------ Stan i walidacja Formularza -------- */
   
  const { setValue,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, }, } = useForm<PaymentRequest>({
      resolver: yupResolver(paymentSchema),
      mode    : 'onBlur', // Walidacja na zdarzeniu onBlur

    });

  /* ------------ Wyliczanie wartości netto i brutto -------- */
  // Watch fields
  const tax = watch('tax');

  useEffect(
    () => {
      const subscription = watch((
        newValue, { name, type, }
      ) => {
        if (type === 'change' && tax) {
          if (name === 'netValue' && newValue.netValue !== undefined) {

            // Przeliczenie grossValue na podstawie netValue i tax
            const calculatedGross = parseFloat((newValue.netValue as number * (1 + tax / 100)).toFixed(2));
            setValue(
              'grossValue', calculatedGross, { shouldValidate: true, }
            );
          }
          else if (name === 'grossValue' && newValue.grossValue !== undefined) {

            // Przeliczenie netValue na podstawie grossValue i tax
            const calculatedNet = parseFloat((newValue.grossValue as number / (1 + tax / 100)).toFixed(2));
            setValue(
              'netValue', calculatedNet, { shouldValidate: true, }
            );
          }
          else if (name === 'tax' ) {

            // Przeliczenie grossValue na podstawie aktualnego netValue i nowego tax
            const currentNetValue = watch('netValue');
            if (currentNetValue !== undefined) {

              // @ts-ignore
              const recalculatedGross = parseFloat((currentNetValue * (1 + newValue.tax / 100)).toFixed(2));
              setValue(
                'grossValue', recalculatedGross, { shouldValidate: true, }
              );
            }
          }
        }
      });

      return () => 
        subscription.unsubscribe();
    }, [ watch, setValue, tax, ]
  );

  /* ------------ Ustalenie daty płatności która jest większa niż dzień faltury -------- */
  const startDate = moment(watch('invoiceDate')).format('YYYY-MM-DD');

  const start = moment(
    startDate, 'YYYY-MM-DD'
  );
  const end = moment(); // Trzymaj jako obiekt moment

  const daysDifference = end.diff(
    start, 'days'
  );

  /* ------------ Walidacja Backend -------- */
  
  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);

  /* ------------ Wysyłanie Danych do Backendu -------- */
  
  const { mutate, isLoading, } = useMutation(
    async (data: PaymentRequest) => {
      const response = await fetch(
        `${CONFIG.URL}/api/payment/`, {
          method     : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ ...data, }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('payments');
        queryClient.invalidateQueries([ 'paymentAddOne', id, ]);
        navigate(-1);
      },
    }
  );

  /* ------------ Akcja wysyłania formularza -------- */
  
  const onSubmit = (data: PaymentRequest) => {
    mutate(data);
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  
  if (paymentIsLoading) return <Loader />;

  if (paymentIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
   
  /* ------------ Renderowanie formularza -------- */
  
  return (
    <div className='carrierForm'>
      <PageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <HeaderSide title='Dodawanie kopi faktury właściciela.' icon={<RequestQuoteOutlinedIcon />} />
          <Button
            className='button delete'
            type='button'
            text='Reset'
            style={{ marginRight: '20px', }}
            handleClick={() => {
              reset();
            }
            }
          />
        </div>
        
        {!isLoading && <div className='empty' />}
        {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
           )}
        <div className='wrapper'>
          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <Fieldset legend='Nagłówek faktury:' direction='row' style={{ flex: 1, minWidth: '300px', }} >
                <InputText
                  placeholder='XX-XX-ZA-MA'
                  id='Numer umowy'
                  name='contractNumber'
                  maxLength={13}
                  minLength={3}
                  required
                  errors={errors.contractNumber}
                  register={register}
                  disabled
                  value={watch('contractNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='123/23'
                  id='Numer faktury'
                  name='invoiceNumber'
                  maxLength={50}
                  value={watch('invoiceNumber')}
                  errors={errors.invoiceNumber}
                  register={register}
                  title='Numer faktury lub informacja o płatności wynikającej z umowy.'
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputDate
                  id='Data faktury'
                  required
                  name='invoiceDate'
                  min={365}
                  max={730}
                  errors={errors.invoiceDate}
                  register={register}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputDate
                  id='Data płatności'
                  required
                  name='paymentDate'
                  min={daysDifference}
                  max={730}
                  errors={errors.paymentDate}
                  register={register}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
              <Fieldset legend='Wartości faktury:' direction='row' style={{ flex: 1, minWidth: '300px', alignContent: 'flex-start', }}>
                <InputNumber
                  placeholder='100 zł'
                  id='Wartość netto'
                  name='netValue'
                  value={watch('netValue')}
                  errors={errors.netValue}
                  register={register}
                  required
                />
                <InputNumber
                  placeholder='23 %'
                  id='Vat %'
                  name='tax'
                  min={0}
                  max={23}
                  step={1}
                  value={watch('tax')}
                  errors={errors.tax}
                  register={register}
                  required
                  disabled
                />
                <InputNumber
                  placeholder='123 zł'
                  id='Wartość brutto'
                  name='grossValue'
                  errors={errors.grossValue}
                  value={watch('grossValue')}
                  register={register}
                  required
                />
                <InputSelect
                  id='Forma płatności'
                  name='formPayment'
                  value={watch('formPayment')}
                  required
                  options={{ przelew: 'Przelew', gotowka: 'Gotówka', }} register={register}
                />
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%', }}>
              <Fieldset
                legend='Właściciel:' direction='row'
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0px 12px 12px 0px', }}
              >
                <div className='inputRadio__label' style={{ width: '97%', margin: '36px 12px 12px 12px', padding: '16px', backgroundColor: 'rgb(140, 163, 186)', borderRadius: '4px', }}>
                  <span className='inputRadio__element'>{payment?.owner?.name}</span>
                  <span className='inputRadio__element'>{payment?.owner?.street} {payment?.owner?.streetNumber}</span>
                  <span className='inputRadio__element'>NIP:{payment?.owner?.NIP}</span>
                </div>
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%', }}>
              <Fieldset legend='Uwagi:' direction='row' style={{ flex: 1, minWidth: '300px', }}>
                <Textarea
                  placeholder='....'
                  id='Opis faktury (zakres faktury)'
                  name='paymentThing'
                  maxLength={1000}
                  errors={errors.paymentThing}
                  register={register}
                  required
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
            </div>
            <div className='carrierMaterialAdd__buttonsContainer' style={{ backgroundColor: 'white', padding: '24px', }}>
              <Button type='submit' text='Zapisz +1' />
              <Button
                className='button cancel'
                type='button'
                text='Anuluj'
                handleClick={() => {
                  navigate(-1);
                }
                }
              />
            </div>
          </Form>
        </div>
      </PageContainer>
    </div>
  );
});
