/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as yup from 'yup';
import { OwnerRequest, } from '../../../types';

// Schemat walidacji zakładania i aktualizacji Customer (Klient)
// @ts-ignore
export const ownerSchema: yup.ObjectSchema<
  OwnerRequest,
  yup.AnyObject,
  {
    isActive: undefined;
    NIP: undefined;
    REGON: undefined;
    name: undefined;
  },
  ''
> = yup.object().shape({
  isActive: yup.boolean().required(),
  NIP     : yup
    .string()
    .required('NIP jest wymagany.')
    .min(
      10, 'Pole powinno zawierać min 10 znaków.'
    )
    .max(
      13, 'Pole powinno zawierać max 13 znaków.'
    ),

  REGON: yup
    .string()
    .notRequired()
    .test(
      'min-length', 'Pole powinno zawierać min 3 znaków.', value => 
        !value || value.length >= 3
    )
    .test(
      'max-length', 'Pole powinno zawierać max 10 znaków.', value => 
        !value || value.length <= 10
    ),

  name: yup
    .string()
    .required('Nazwa właściciela jest wymagany')
    .min(
      3, 'Nazwa właściciela powinien zawierać max 3 znaki'
    )
    .max(
      150, 'Nazwa właściciela powinien zawierać max 150 znaków'
    ),

  nameOwner: yup
    .string()
    .required('Nazwa firmy jest wymagana.')
    .min(
      3, 'Nazwa firmy powinien zawierać max 3 znaki.'
    )
    .max(
      150, 'Nazwa firmy powinien zawierać max 150 znaków.'
    ),

  street: yup
    .string()
    .required('Nazwa ulicy nie może być pusta.')
    .min(
      3, 'Nazwa ulicy powinien zawierać min 3 znaki'
    )
    .max(
      60, 'Nazwa ulicy powinien zawierać max 60 znaków'
    ),
  
  streetNumber: yup
    .string()
    .required('Numer ulicy nie może być pusty.')
    .min(
      1, 'Numer ulic powinien zawierać min 1 znak.'
    )
    .max(
      10, 'Numer ulicy powinien zawierać max 10 znaków.'
    ),

  zipCode: yup
    .string()
    .required('Kod pocztowy nie może być pusty.')
    .max(
      6, 'Kod pocztowy powinien zawierać max 6 znaków.'
    )
    .matches(
      /^\d{2}-\d{3}$/, 'Kod pocztowy musi mieć format XX-XXX.'
    ),
  
  city: yup
    .string()
    .required('Miasto nie może być puste.')
    .max(
      60, 'Miasto powinno zawierać max 60 znaków.'
    ),

  registrationDate: yup
    .date()
    .notRequired()
    .required('Data rejestracji firmy nie może być pusta.'),

  accountNumber: yup
    .string()
    .notRequired()
    .max(
      28, 'Numer konta bankowego powinno mieć długość max 28 znaków.'
    ),

  // .matches(
  //   /^(PL)?\d{26}$/, 'Numer konta musi mieć format IBAN.'
  // ),
    
  contactPerson: yup
    .string()
    .required('Osoba do kontaktu nie może być pusta.')
    .max(
      60, 'Osoba do kontaktu powinna zawierać max 60 znaków.'
    ),
  
  contactEmail: yup
    .string()
    .required('Email kontaktowy nie może być pusty.')
    .max(
      100, 'Email kontaktowy powine zawierać max 100 znaków.'
    )
    .email('Pole powinna byc mailem nazwa@domena.pl.'),

  contactTel: yup
    .string()
    .notRequired()
    .max(
      100, 'Numer telefonu powine zawierać max 50 znaków.'
    ),
  
  contactWww: yup
    .string()
    .notRequired()
    .url('Adres www powinien zaczynać się od http://')
    .max(
      100, 'Adres WWW powinien zawierać max 50 znaków.'
    ),
});
