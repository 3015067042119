import React, { Dispatch, SetStateAction, SyntheticEvent, } from 'react';
import { useMutation, } from 'react-query';
import { queryClient, } from '../../..';
import { Button, HighlightOffOutlinedIcon, } from '../../../components';
import { CONFIG, } from '../../../utils/config';
import './ContractDelete.css';

interface Props {
  id: string;
  viewPopup: Dispatch<SetStateAction<boolean>>;
}

export const ContractDelete = ({ id, viewPopup, }: Props) => {
  const { isLoading, /* isError, isSuccess, */ mutate, } = useMutation(
    async () => {
      return fetch(
        `${CONFIG.URL}/api/contract/${id}`, {
          method     : 'DELETE',
          credentials: 'include',
        }
      );
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
      },
    }
  );

  const handleDelete = (e: SyntheticEvent) => {
    e.preventDefault();
    mutate();
  };

  if (isLoading) {
    return <p>Zapisywanie zmian...</p>;
  }
  return (
    <div className='carrierMaterialDelete'>
      <div className='carrierMaterialDelete__iconC'>
        <HighlightOffOutlinedIcon className='carrierMaterialDelete__icon' />
      </div>
      <h2 className='carrierMaterialDelete__title'>
        Czy chcesz usunąć element od id{' '}
        <span className='carrierMaterialDelete__id'>{id}</span> !!!
      </h2>
      <div className='carrierMaterialDelete__buttons'>
        <Button
          type='button'
          text='Usuń'
          className='button delete'
          onClick={e => 
            handleDelete(e)}
        />
        <Button
          type='button'
          text='Anuluj'
          className='button cancel'
          onClick={() => 
            viewPopup(curr => 
              !!curr)}
        />
      </div>
    </div>
  );
};
