import React, { useState, } from 'react';
import { useQuery, } from 'react-query';
import { CONFIG, } from '../../utils/config';
import { DataColumn, OwnerEntity, OwnersResponse, } from '../../types';
import { AddButton, BasicTable, HeaderSide, Loader, PageContainer, PersonRemoveOutlinedIcon, Popup, /* Popup, */ SearchBar, } from '../../components';
import { OwnerDelete, } from './ownerDeleta/OwnerDelete';

import './owners.css';

/* ------------ Powiązanie nagłówka tabeli z danymi -------- */

const columns: DataColumn[] = [
  {
    name: 'Nazwa',
    data: 'name',
  },
  {
    name: 'NIP/PESEL',
    data: 'NIP',
  },
  {
    name: 'REGON/NR.DOWODU',
    data: 'REGON',
  },

  {
    name: 'Miasto',
    data: 'city',
  },
];

export const Owners = () => {

  /* ------------ Logika wyszukiwania włascicieli -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');

  // id usuwanego
  const [ idDelete, setIdDelete, ] = useState<string>('');

  // aktywny popup
  const [ popup, setPopup, ] = useState<boolean>(false);

  /* ------------ Pobieranie wszytkach włascicieli -------- */
  const {
    isLoading,
    isError,
    data: owners,
  } = useQuery(
    'owners', async (): Promise<OwnerEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/owner`, {
          credentials: 'include',
        }
      );
      const { data, }: OwnersResponse = await res.json();
      return data;
    }
  );

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (isLoading) return <Loader />;

  if (isError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <main className='contractView'>
      <PageContainer>
        <HeaderSide
          title='Właściciele'
          icon={<PersonRemoveOutlinedIcon />}
          button={<AddButton
            text='Dodaj nowy'
            action='new'
          />}
        />
        <SearchBar handleSearch={setSearch} search={search} />
        {owners && (
          <BasicTable
            dataColumns={columns}
            dataRows={owners}
            viewPopup={setPopup}
            popup={popup}
            setIdDelete={setIdDelete}
            search={search}
          />
        )}
      </PageContainer>
      {popup && idDelete && <Popup viewPopup={setPopup} >
        <OwnerDelete id={idDelete} viewPopup={setPopup}/>
      </Popup>}
    </main>
  );
};
