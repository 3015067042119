/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, } from 'react';
import { useNavigate, } from 'react-router-dom';

export const useUnloadLogout = () => {
  const navigate = useNavigate();
  
  useEffect(
    () => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        localStorage.removeItem('login');
        localStorage.removeItem('preLogoutPath');

        // Wyczyszczenie ciasteczek
        document.cookie.split(';').forEach(c => {
          document.cookie = `${c.trim().split('=')[ 0 ] }=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        });

        event.returnValue = 'Trwa proces wylogowywania. Czy na pewno chcesz opuścić stronę?'; // Wymagane dla niektórych przeglądarek
      };

      window.addEventListener(
        'beforeunload', handleBeforeUnload
      );

      return () => {
        window.removeEventListener(
          'beforeunload', handleBeforeUnload
        );
      };
    }, [ navigate, ]
  );
};