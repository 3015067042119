import React, { Dispatch, SetStateAction, } from 'react';
import './SearchBar.css';
import { SearchOutlinedIcon, } from '../icons';

interface SearchBarProps {
  handleSearch: Dispatch<SetStateAction<string>>;
  search: string;
}

export const SearchBar = ({ handleSearch, search, }: SearchBarProps) => {
  return (
    <div className='searchBar'>
      <div className='searchBar__inputContainer'>
        <SearchOutlinedIcon className='searchBar__icon' />
        <input
          type='search'
          placeholder='Szukaj'
          className='searchBar__input'
          value={search}
          onChange={e => 
            handleSearch(e.target.value)}
        />
      </div>
    </div>
  );
};
