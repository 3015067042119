import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { QueryClient, QueryClientProvider, } from 'react-query';
import { ReactQueryDevtools, } from 'react-query/devtools';
import { App, } from './App';
import { PaymentFilterProvider, } from './contexts/payment/PaymentFilterProvider';

/* ------------ Stan zapytań -------- */
export const queryClient = new QueryClient();

/* ------------ Renderowanie Całej aplikacji -------- */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <PaymentFilterProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools />
    </PaymentFilterProvider>
  </QueryClientProvider>
</React.StrictMode>);
