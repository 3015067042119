import React, { useState, } from 'react';
import { CarrierEntity, } from '../../../../types';
import { AddModal, PersonAddAlt1OutlinedIcon, Popup, } from '../../../../components';
import { OwnersList, } from '../../carrierOwner/OwnersListOne';

interface Props {
  carrier: CarrierEntity;
}

export const CarrierOwner = ({ carrier, }: Props) => {
  const { owner, } = carrier;

  /* ------------ Logika wyszukiwania klientów -------- */
  // aktywny popup
  const [ popup, setPopup, ] = useState<boolean>(false);

  /* ------------ Renderowanie Widoku -------- */
  return (
    <div className='customerInfo'>
      <div className='customerInfo__left' >
        <div className='customerInfo__card' style={{ width: '96%', }}>
          {owner ? <><div className='customerInfo__headerContainer'>
            <PersonAddAlt1OutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} />
            <h5 className='customerInfo__header'>Właściciel:</h5>
          </div>
          <div className='customerInfo__list'>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Własciciel: { owner.name }</p>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>NIP: { owner.NIP }</p>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Ulica: { owner.street } { owner.streetNumber }</p>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', width: '48%', }}>Miasto: { owner.zipCode } { owner.city }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Kontakt: { owner.contactPerson }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Telefon: {owner.contactTel}</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>Email: {owner.contactEmail}</p>
          </div></> : (<div className='customerInfo__headerContainer' style={{ justifyContent: 'center', }}>
            <h2>Dodaj własciciela:</h2>
            <AddModal viewPopup={setPopup}/>
          </div>)}
        </div>
      </div>
      {popup && <Popup viewPopup={setPopup} >
        <OwnersList viewPopup={setPopup} id={carrier.id } />
      </Popup>}
    </div>
  );
};
