import React from 'react';

interface Props {
  id: string;
}

export const OwnerContracts = ({ id, }:Props) => {
  return (
    <div>
      Umowy { id}
    </div>
  );
};
