/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  BaseSyntheticEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import moment from 'moment';
import { queryClient, } from '../../..';

import {
  ArticleOutlinedIcon,
  BreakLine,
  Button,
  Fieldset,
  Form,
  HeaderSide,
  InputDate,
  ContractNumber,
  InputT,
  PageContainer,

  /* Textarea, */
} from '../../../components';

import { ContractRequest, ContractResponse, } from '../../../types';
import { useAuth, } from '../../../hooks/useAuth';

import './ContractForm.css';
import { CONFIG, } from '../../../utils/config';

export const ContractForm = () => {

  /* ------------ Sekcja Nawigacji na stronie -------- */
  const { id, } = useParams();

  const navigate = useNavigate();

  /* ------------ Logika stana formularza -------- */

  // dane startowe formularza
  const initialContract: ContractRequest = {
    numberContractCustomer   : '',
    startContractDateCustomer: moment().format('YYYY-MM-DD'),
    endContractDateCustomer  : moment().format('YYYY-MM-DD'),
    customer                 : '',
    city                     : '',
    street                   : '',
    numberContractOwner      : '',
    startContractDateOwner   : moment().format('YYYY-MM-DD'),
    endContractDateOwner     : moment().format('YYYY-MM-DD'),
    owner                    : '',
    comment                  : null ?? '',
  };

  // Stan formularza
  const [ fields, setFields, ] = useState<ContractRequest>({ ...initialContract, });

  // Funkcji aktualizacji formularza
  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value, checked, } = e.target;
    setFields({ ...fields, [ name ]: checked || value, });
  };

  /* ------------ Generator numeru umowy -------- */
  const { data, } = useAuth();
  const contractNumGenerator = () => {
    const cng = `${moment().format('YY')}${
      fields.city? `-${fields.city.split('').slice(
        0, 2
      )
        .join('')
        .toLocaleUpperCase()}`: ''
    }-${data.name.split('')[ 0 ]}${data.lastName.split('')[ 0 ]}`;
    return cng;
  };

  /* ------------ Focus na wybrane pole input-a -------- */

  const inputElement = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => 
          inputElement.current?.focus(), 200
      );
    }, []
  );

  /* ------------ Logika pobierania jednego rekordu w celu edycji oraz aktualizacja stanu-------- */
  // fetch GET One Contract
  useQuery(
    'contract', async () => {
      const res = await fetch(
        `${CONFIG.URL}/api/contract/${id ?? ''}`, {
          credentials: 'include',
        }
      );
      const contract: ContractResponse = await res.json();
      setFields({
        numberContractCustomer   : contract.data?.numberContractCustomer,
        startContractDateCustomer: contract.data?.startContractDateCustomer,
        endContractDateCustomer  : contract.data?.endContractDateCustomer,
        customer                 : contract.data?.customer,
        city                     : contract.data?.city,
        street                   : contract.data?.street,
        numberContractOwner      : contract.data?.numberContractOwner,
        startContractDateOwner   : contract.data?.startContractDateOwner,
        endContractDateOwner     : contract.data?.endContractDateOwner,
        owner                    : contract.data?.owner,
        comment                  : contract.data?.comment,
      });
      return contract.data;
    }
  );

  /* ------------ Logika Wysyłania i Aktualizacji Formularza -------- */

  // fetch POST || PATCH Data Table
  const { isLoading, isError, /* isSuccess, */ mutate, } = useMutation(
    async (data: ContractRequest) => {
      return fetch(
        `${CONFIG.URL}/api/contract/${id ?? ''}`, {
          method     : id ? 'PATCH' : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify(data),
          credentials: 'include',
        }
      );
    }, {
      onSuccess: () => {
        setFields(initialContract);
        queryClient.invalidateQueries('contracts');
        navigate('/umowy-klient');
      },
    }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      numberContractCustomer   : contractNumGenerator(),
      startContractDateCustomer: fields.startContractDateCustomer,
      endContractDateCustomer  : fields.endContractDateCustomer,
      customer                 : fields.customer,
      city                     : fields.city,
      street                   : fields.street,
      numberContractOwner      : fields.numberContractOwner,
      startContractDateOwner   : fields.startContractDateOwner,
      endContractDateOwner     : fields.endContractDateOwner,
      owner                    : fields.owner,
      comment                  : fields.comment,
    });
  };

  // Renderowanie
  if (isLoading) {
    return <p>Zapisywanie zmian...</p>;
  }
  if (isError) {
    return <p>Wystąpił błąd ...</p>;
  }
  return (
    <div className='contractForm'>
      <PageContainer>
        <HeaderSide title='Dodawanie Umowy' icon={<ArticleOutlinedIcon />} />
        <Form handleSubmit={handleSubmit}>
          <div className='carrierMaterialAdd__buttonsContainer'>
            <Button type='submit' text={id ? 'Aktualizacja' : 'Zapisz'} />
            <Button
              className='button cancel'
              type='button'
              text='Anuluj'
              handleClick={() => 
                navigate(-1)}
            />
          </div>
          <Fieldset legend='Numer Umowy Klient:' direction='row'>
            <ContractNumber value={contractNumGenerator()} />
          </Fieldset>
          <Fieldset legend='Daty umowy z klientem:' direction='row'>
            <InputDate
              min={0}
              max={365}
              name='startContractDateCustomer'
              id='Umowa start RRRR-MM-DD'
              handleChange={e => 
                handleChange(e)}
              required
            />
            <InputDate
              min={0}
              max={365}
              name='endContractDateCustomer'
              id='Umowa koniec RRRR-MM-DD'
              handleChange={e => 
                handleChange(e)}
            />
          </Fieldset>
          <BreakLine />
          <Fieldset legend='Dane Klienta:' direction='column'>
            <InputT
              placeholder='McDonald`s'
              id='Klient'
              name='customer'
              minLength={3}
              maxLength={60}
              value={fields.customer || ''}
              handleChange={e => 
                handleChange(e)}
              required
              focus
            />
            <InputT
              placeholder='Zabrze / Bytom / Katowice'
              id='Miasto'
              name='city'
              minLength={3}
              maxLength={60}
              value={fields.city || ''}
              handleChange={e => 
                handleChange(e)}
              required
            />
            <InputT
              placeholder='Wolności 56 / Modrzewiowa 4A'
              id='Ulica'
              name='street'
              minLength={3}
              maxLength={60}
              value={fields.street || ''}
              handleChange={e => 
                handleChange(e)}
              required
            />
          </Fieldset>
          <BreakLine />
          <Fieldset legend='Nagłówek Właściciel:' direction='row'>
            <InputT
              placeholder='42-23-KR-..'
              id='Numer umowy z właścicielem'
              name='numberContractOwner'
              minLength={3}
              maxLength={40}
              value={fields.numberContractOwner || ''}
              handleChange={e => 
                handleChange(e)}
              required
            />
          </Fieldset>
          <Fieldset legend='Daty umowy z właścicielem:' direction='row'>
            <InputDate
              min={0}
              max={365}
              name='startContractDateOwner'
              id='Umowa start RRRR-MM-DD'
              handleChange={e => 
                handleChange(e)}
              required
            />
            <InputDate
              min={0}
              max={365}
              name='endContractDateOwner'
              id='Umowa koniec RRRR-MM-DD'
              handleChange={e => 
                handleChange(e)}
            />
          </Fieldset>
          <BreakLine />
          <Fieldset legend='Dane Właściciela:' direction='row'>
            <InputT
              placeholder='Irene / Max / ....'
              id='Klient'
              name='owner'
              minLength={3}
              maxLength={60}
              value={fields.owner || ''}
              handleChange={e => 
                handleChange(e)}
            />
          </Fieldset>
          <BreakLine />
          <Fieldset legend='Komentarz:' direction='row'>
            {/* <Textarea
              name='comment'
              id='Komentarz'
              placeholder='......'
              minLength={3}
              maxLength={1000}
              value={fields.comment ?? ''}
              errors={errors.comment}
              register={register} /> */}
          </Fieldset>
        </Form>
      </PageContainer>
    </div>
  );
};
