import React, { Dispatch, SetStateAction, } from 'react';
import { AddOutlinedIcon, } from '../icons';

import './AddButton.css';

interface Props {
  viewPopup: Dispatch<SetStateAction<boolean>>;
}
export const AddModal = ({ viewPopup, }:Props) => {
  return (
    <button className='addButton' type='button' onClick={() => 
      viewPopup(prev => 
        !prev)}>
      <span className='addButtonIcon'>
        <AddOutlinedIcon />
      </span>
      Dodaj
    </button>
  );
};
