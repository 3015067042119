import React from 'react';
import './customerSub.css';
import { AlternateEmailOutlinedIcon, ApartmentOutlinedIcon, LanguageOutlinedIcon, OutlinedFlagRoundedIcon, PortraitOutlinedIcon, RememberMeOutlinedIcon, } from '../../../../components';
import { CustomerEntity, } from '../../../../types';

interface Props {
  customer: CustomerEntity;
}

export const CustomerInfo = ({ customer, }:Props) => {
  return (
    <div className='customerInfo'>
      <div className='customerInfo__left'>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <ApartmentOutlinedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} /> 
            <h5 className='customerInfo__header'>Dane Firm</h5>
          </div>          
          <div className='customerInfo__list'>
            <p className='customerInfo__data' style={{ textTransform: 'uppercase', fontWeight: 'bold', }}>{ customer.nameCustomer }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>NIP: { customer.NIP }</p>
            <p className='customerInfo__data' style={{ width: '48%', }}>REGON: {customer.REGON}</p>
            <p className='customerInfo__data'>IBAN: {customer.accountNumber}</p>
          </div>
        </div>
        <div className='customerInfo__card'>
          <div className='customerInfo__headerContainer'>
            <OutlinedFlagRoundedIcon style={{ color: 'rgb(18, 31, 67)', paddingLeft: '10px', width: '40px', height: '40px', }} />
            <h5 className='customerInfo__header'>Adres Firmy</h5>
          </div>
          <div className='customerInfo__list'>
            <p className='customerInfo__data'>ul. {customer.street} {customer.streetNumber}</p>
            <p className='customerInfo__data' style={{ width: '48%', }}> { customer.zipCode } { customer.city }</p>
          </div>
        </div>
      </div>
      <div className='customerInfo__right'>
        <div className='customerInfo__card'>
          <h5 className='customerInfo__header'>Dane Kontaktowe</h5>
          <div className='customerInfo__list'>
            {customer.contactPerson && <p className='customerInfo__data'>
              <PortraitOutlinedIcon className='infoHeader__icon' style={{ border: '1px solid rgb(211, 230, 243)', color: 'rgb(18, 31, 67)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{ customer.contactPerson }</p>}
            {customer.contactEmail && <a className='customerInfo__data' href={`mailto:${customer.contactEmail}`} target='_blank' rel='noreferrer'>
              <AlternateEmailOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(39, 206, 136)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{customer.contactEmail}</a>}
            {customer.contactTel && <a className='customerInfo__data' href={`tel:${customer.contactTel}`} target='_blank' rel='noreferrer'>
              <RememberMeOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(36, 153, 239)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{customer.contactTel}</a>}
            {customer.contactWww && <a className='customerInfo__data' href={customer.contactWww} target='_blank' rel='noreferrer'>
              <LanguageOutlinedIcon className='infoHeader__icon' style={{ backgroundColor: 'rgb(255, 151, 119)', color: 'rgb(255,255,255)', padding: '4px', width: '40px', height: '40px', borderRadius: '4px', }} />{customer.contactWww}</a>}
          </div>
        </div>
      </div>
    </div>
  );
};
