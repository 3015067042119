/* eslint-disable react/no-unused-prop-types */
import React, { Dispatch, ReactNode, SetStateAction, } from 'react';
import './HeaderSide.css';

interface haederSideProps {
  handleAdd?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  title: string;
  icon: ReactNode;
  button?: ReactNode;
  button2?: ReactNode;
}

export const HeaderSide = ({ title, icon, button, button2, }: haederSideProps) => {
  return (
    <div className='haederSide'>
      <div className='haederSide__left'>
        <div className='haederSide__icon'>{icon}</div>
        <h2 className='haederSide__title'>{title}</h2>
      </div>
      <div className='haederSide__titlebuttonsContainer'>
        {button}
        {button2}
      </div>
    </div>
  );
};
