/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { PaymentRequest, } from '../../../types';

// Schemat walidacji zakładania i aktualizacji Payment Cost
// @ts-ignore
export const paymentSchema: yup.ObjectSchema<
  PaymentRequest,
  yup.AnyObject,
   {

  //  city: string;
  //  zipCode: string;
  //    street: string;
  //    streetNumber: string;
  //    direction: string | null | undefined;
  //    lng: number | null | undefined;
  //    lat: number | null | undefined;
  //    width: number;
  //    height: number;
  //  isActive: boolean;
  //    isLight: boolean;
  //    carrierMaterial: string;
  //    comment: string | null | undefined;
  },
  ''
> = yup.object().shape({

  // * Wartość ustawiona domyślnie nie sterowana input o ratości 'przychod' | 'koszt'
  typePayment: yup
    .string()
    .required('Typ płatności niemoże być pusty.')
    .oneOf(
      [ 'przychod', 'koszt', ], 'Nieprawidłowy typ płatności'
    )
    .max(
      10, 'Maksymalna długość nazwy to 10 znaków.'
    ),

  // ! ok
  contractNumber: yup
    .string()
    .required('Numer umowy nie może być pusty.')
    .min(
      3, 'Numer umowy powinno zawierać min 3 znaki.'
    )
    .max(
      13, 'Pole powinno zawierać max 13 znaków.'
    ),

  // ! ok
  invoiceNumber: yup
    .string()
    .notRequired()
    .max(
      50, 'Pole powinno zawierać max 50 znaków.'
    ),

  // ! ok
  paymentThing: yup
    .string()
    .required('Opis faktury jest wymagany.')
    .max(
      1000, 'Zakres płatności powinien zawierać max 1000 znaków.'
    ),

  // ! ok
  netValue: yup
    .number()
    .transform(value => 
      (isNaN(value) ? null : value))
    .required('Wartość netto jest wymagana.')
    .positive('Wartość musi być dodatnia.')
    .max(
      10000000, 'Wartość nie może być wieksza niż 100000 zł.'
    ),

  // ! ok
  tax: yup
    .number()
    .positive('Stawka Vat musi być dodatnia.')
    .min(
      0, 'Stawka nie może być mniejsza niż 0 %'
    )
    .max(
      23, 'Stawka nie może być wieksza niż 23 %'
    ),

  // ! ok
  grossValue: yup
    .number()
    .transform(value => 
      isNaN(value) ? null : value)
    .required('Wartość brutto jest wymagana.')
    .positive('Wartość musi być dodatnia.')
    .max(
      10000000, 'Wartość nie może być wieksza niż 100000 zł.'
    ),
  formPayment: yup
    .string()
    .required('Form płatności nie może być pusta.')
    .oneOf(
      [ 'przelew', 'gotowka', ], 'Nieprawidłowa forma płatności'
    )
    .max(
      10, 'Maksymalna długość nazwy to 8 znaków.'
    ),

  // ! ok
  invoiceDate: yup
    .date()
    .required('Data faktury jest wymagana.'),

  // ! ok
  paymentDate: yup
    .date()
    .required('Data płatności jest wymagana.'),
  
  // * Wartość ustawiona domyślnie nie sterowana input o watości new Date()
  wasPaymentDate: yup
    .date()
    .required('Data zapłacenia jest wymagana.'),

  // * Wartość ustawiona domyślnie nie sterowana domyślnie false
  isPayment: yup
    .boolean()
    .required(),

  // Wartość string z Id relacji
  owner: yup
    .string()
    .nullable()
    .notRequired(),
});
