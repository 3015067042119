/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, } from 'react';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useMutation, } from 'react-query';
import { useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';

import { CONFIG, } from '../../utils/config';
import { LoginRequest, ServerErrors, } from '../../types';
import { loginSchema, } from './LoginSchema';

import {
  ErrorMessage,
  InputPassword,
  InputText,
  Loader,
} from '../../components';

import './LoginView.css';

export const LoginView = () => {

  // Nawigacja
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  // LocalState
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, },
  } = useForm<LoginRequest>({
    resolver: yupResolver(loginSchema),
    mode    : 'onBlur', // Walidacja na zdarzeniu onBlur
  });

  // Error z servera
  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);

  // Zapytanie do pobrania kategorii z bazy danych
  const { mutate, isLoading, } = useMutation(
    async (data: LoginRequest) => {
      const response = await fetch(
        `${CONFIG.URL}/api/auth/login`, {
          method     : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify(data),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }

      return response.json();
    }, {
      onSuccess: data => {

        // Zaktualizuj dane użytkownika w cache
        localStorage.setItem(
          'login', JSON.stringify(data)
        );
        const preLogoutPath = localStorage.getItem('preLogoutPath');
        if (preLogoutPath) {
          navigate(preLogoutPath);
          localStorage.removeItem('preLogoutPath'); // Usuwamy zapisaną ścieżkę
        }
        else {
          navigate(
            from, { replace: true, }
          );
        }
      },
    }
  );
  
  const onSubmit = (data: LoginRequest) => {
    mutate(data);
  };

  return (
    <div className='login'>
      <div className='login__wrapper'>
        <h1 className='login__title'>Logowanie</h1>
        {!isLoading && <div className='empty' />}
        {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
        )}
        <form
          className='login__form'
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='login__container'>
            <InputText
              placeholder='Janina'
              id='Imię'
              name='name'
              maxLength={20}
              required
              focus
              register={register}
              errors={errors.name}
              value={watch('name')}
            />
            {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
            <InputPassword
              register={register}
              placeholder='*****'
              id='Hasło'
              name='password'
              maxLength={20}
              required
              errors={errors.password}
              value={watch('password')}
            />
            {/* {serverErrors?.password && <p>{serverErrors.password}</p>} */}
            <button className='login__button' type='submit'>
              Zaloguj
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
