/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { InputHTMLAttributes, } from 'react';
import './Input.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: any;
  name: string
  label: JSX.Element;
  id?: string;
  defaultChecked: boolean
}

export const InputRadioList = ({
  register,
  name,
  id,
  label,
  defaultChecked,
  ...rest
}: InputProps) => {

  return (
    <div className='radio'>
      <label
        className='radio__label radio__label--list'
        htmlFor={id}
      >
        <input
          type='radio'
          className='radio__input'
          id={id}
          defaultChecked={defaultChecked}
          {...register(name)}
          {...rest}
        />
        {label}
        <span className='radio__span' />
      </label>
    </div>
  );
};
