import React, { Dispatch, SetStateAction, SyntheticEvent, } from 'react';
import { useMutation, useQuery, } from 'react-query';
import { queryClient, } from '../../..';
import { Button, HighlightOffOutlinedIcon, Loader, } from '../../../components';
import { CONFIG, } from '../../../utils/config';

import './CustomerDelete.css';
import { CustomerEntity, CustomerResponse, } from '../../../types';

interface Props {
  id: string;
  viewPopup: Dispatch<SetStateAction<boolean>>;
}

export const CustomerDelete = ({ id, viewPopup, }: Props) => {

  /* ------------ Pobieranie jednego klienta -------- */
  const {
    isLoading:getIsLoading,
    isError:getIsError,
    data: customer,
  } = useQuery(
    [ 'customer', id, ], async (): Promise<CustomerEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/customer/${id}`, {
          credentials: 'include',
        }
      );
      const { data, }: CustomerResponse = await res.json();
      return data;
    }
  );
  
  /* ------------ Wysyłanie Danych do Backendu -------- */

  const { isLoading:deleteIsLoading, isError:deleteIsError, /* isSuccess, */ mutate, } = useMutation(
    async () => {
      return fetch(
        `${CONFIG.URL}/api/customer/${id}`, {
          method     : 'DELETE',
          credentials: 'include',
        }
      );
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        viewPopup(false);
      },
    }
  );

  const handleDelete = (e: SyntheticEvent) => {
    e.preventDefault();
    mutate();
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  if (deleteIsLoading) return <Loader />;
  if (getIsLoading) return <Loader />;
  if (deleteIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  if (getIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie Widoku -------- */
  return (
    <div className='carrierMaterialDelete'>
      <div className='carrierMaterialDelete__iconC'>
        <HighlightOffOutlinedIcon className='carrierMaterialDelete__icon' />
      </div>
      <h2 className='carrierMaterialDelete__title'>
        Czy chcesz usunąć firmę{' '}
        <span className='carrierMaterialDelete__id'>{customer?.name}</span> !!!
      </h2>
      <div className='carrierMaterialDelete__buttons'>
        <Button
          type='button'
          text='Usuń'
          className='button delete'
          onClick={e => 
            handleDelete(e)}
        />
        <Button
          type='button'
          text='Anuluj'
          className='button cancel'
          onClick={() => 
            viewPopup(false)}
        />
      </div>
    </div>
  );
};
