/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import React, {
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import './Input.css';
import { FieldError, } from 'react-hook-form';
import { ServerErrors, } from '../../types';
import { ErrorInput, } from '../errors/ErrorInput';

interface TextareatProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength: number;
  name: string;
  id: string;
  placeholder: string;
  focus?: boolean;
  register: any;
  errors: FieldError | undefined;
  serverErrors?: ServerErrors | null;
  validate?: string
  disabled?: boolean;
}

export const Textarea = ({
  name,
  id,
  maxLength,
  placeholder,
  focus,
  required,
  register,
  errors,
  serverErrors,
  validate,
  value,
  disabled,
  ...rest
}: TextareatProps) => {
  const hasValue = value != null && value !== '';

  // Ref
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => {
          focus && inputRef?.current?.focus();
        }, 200
      );
    }, [ focus, ]
  );
  return (
    <div className='input'>
      <textarea
        ref={inputRef}
        className={`input__data textarea ${required && 'required'}`}
        name={name}
        id={id}
        autoComplete='off'
        tabIndex={0}
        maxLength={maxLength}
        placeholder={placeholder}
        required={required}
        style={{ backgroundColor: disabled ? 'rgb(140, 163, 186)' : '', }}
        {...register(name)}
        {...rest}
      />
      <label className={`input__label ${hasValue || disabled ? 'has-value' : ''}`} style={{ backgroundColor: disabled ? 'transparent' : '', color: disabled ? 'transparent' :'', }} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>
      {!errors && !serverErrors && <div style={{ height: '20px', }}/>}
      <ErrorInput
        errors={errors}
        serverErrors={serverErrors}
        errorName={name}
        validate={validate}
      />
    </div>
  );
};
