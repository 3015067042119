import React from 'react';
import { Link, useLocation, } from 'react-router-dom';
import { AddOutlinedIcon, } from '../icons';

import './AddButton.css';

interface ButtonProps {
  text: string;
  action: string;
}

export const AddButton = ({ text, action, }:ButtonProps) => {
  const { pathname, } = useLocation();
  return (
    <Link className='addButton' to={`${pathname}/${action}`}>
      <span className='addButtonIcon'>
        <AddOutlinedIcon />
      </span>
      {text}
    </Link>
  );
};
