/* eslint-disable react/react-in-jsx-scope */
import { useLocation, Navigate, } from 'react-router-dom';
import { useAuth, } from '../hooks/useAuth';
import { Layout, } from '../views/Layout/Layout';

export const RequireAuth = () => {
  const data = useAuth();
  const location = useLocation();

  return data?.data?.name ? (
    <Layout />
  ) : (
    <Navigate to='/login' state={{ from: location, }} replace />
  );
};
