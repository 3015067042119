/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { InputHTMLAttributes, } from 'react';
import './Input.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: any;
  name: string
  label: string;
  id?: string;
  defaultChecked?: boolean
}

export const InputRadio = ({
  register,
  name,
  id,
  label,
  defaultChecked,
  ...rest
}: InputProps) => {

  return (
    <div className='radio' style={{ flex: 1, }}>
      <label
        className='radio__label'
        htmlFor={id}
      >
        <input
          type='radio'
          className='radio__input'
          id={id}
          defaultChecked={defaultChecked}
          {...register(name)}
          {...rest}
        />
        {label}
        <span className='radio__span' />
      </label>
    </div>
  );
};
