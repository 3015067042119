import React from 'react';
import { Route, Routes, } from 'react-router-dom';
import { RequireAuth, } from './components/RequireAuth';
import { Dashboard, } from './views/Dashboard/DashboardView';
import { Missing, } from './views/MissingView';
import {
  CarrierMaterial,
  CarrierMaterialForm,
  Contract,
  ContractForm,
  Customers,
  CustomerForm,
  LoginView,
  CarrierForm,
  Carriers,
  Carrier,
  Owners,
  OwnerForm,
  Customer,
  Owner,
  Payments,
  PaymentAddForm,
  PaymentPay,
  PaymentEditForm,
  PaymentAddOneForm,
} from './views';

import './styles/reset.css';
import './styles/elements.css';

import { useIdleTimer, } from './hooks/useIdleTimer';
import { useAutoLogout, } from './hooks/useAutoLogout';

import { useUnloadLogout, } from './hooks/useUnloadLogout';

export const App = () => {

  /* ------------ Auto wylogowanie po zamknięciu przeglądarki-------- */
  useUnloadLogout();

  /* ------------ Zmiana czasu do wylogowania oparta na eventach click, scroll,  -------- */
  useIdleTimer();

  /* ------------ Auto wylogowanie po upływie czasu -------- */
  useAutoLogout();

  /* ------------ Renderowanie  -------- */
  return (
    <main className='App'>
      <Routes>
        {/* public routes */}
        <Route path='login' element={<LoginView />} />
        {/* protect route */}
        <Route element={<RequireAuth />}>
          <Route path='/'>
            <Route index element={<Dashboard />} />
            
            {/* ----- Płatności ----- */}
            <Route path='/platnosci'>
              <Route index element={<Payments />} />
              <Route path='newCost'>
                <Route index element={<PaymentAddForm />} />
                <Route path=':id' element={<PaymentAddForm />} />
              </Route>
              <Route path='edit'>
                <Route index element={<PaymentEditForm />} />
                <Route path=':id' element={<PaymentEditForm />} />
              </Route>
              <Route path='plusOneCost'>
                <Route index element={<PaymentAddOneForm />} />
                <Route path=':id' element={<PaymentAddOneForm />} />
              </Route>
              <Route path=':id' element={<PaymentPay />}/>
            </Route>

            {/* ----- Umowy właściciele ----- */}
            <Route path='/umowy-klient'>
              <Route index element={<Contract />} />
              <Route path='new'>
                <Route index element={<ContractForm />} />
                <Route path=':id' element={<ContractForm />} />
              </Route>
              <Route path='edit'>
                <Route index element={<ContractForm />} />
                <Route path=':id' element={<ContractForm />} />
              </Route>
            </Route>

            {/* ----- Umowy właściciele ----- */}
            <Route path='/umowy-wlasciciele'>
              <Route index element={<Customers />} />
              <Route path='new'>
                <Route index element={<CustomerForm />} />
                <Route path=':id' element={<CustomerForm />}/>
              </Route>
              <Route path='edit'>
                <Route index element={<CustomerForm />}/>
                <Route path=':id' element={<CustomerForm />}/>
              </Route>
              <Route path=':id' element={<Customer />}/>
            </Route>

            {/* ----- Klient ----- */}
            <Route path='/klient'>
              <Route index element={<Customers />} />
              <Route path='new'>
                <Route index element={<CustomerForm />} />
                <Route path=':id' element={<CustomerForm />}/>
              </Route>
              <Route path='edit'>
                <Route index element={<CustomerForm />}/>
                <Route path=':id' element={<CustomerForm />}/>
              </Route>
              <Route path=':id' element={<Customer />}/>
            </Route>

            {/* ----- Własciciel ----- */}
            <Route path='/wlasciciel'>
              <Route index element={<Owners />} />
              <Route path='new'>
                <Route index element={<OwnerForm />} />
                <Route path=':id' element={<OwnerForm />} />
              </Route>
              <Route path='edit'>
                <Route index element={<OwnerForm />} />
                <Route path=':id' element={<OwnerForm />} />
              </Route>
              <Route path=':id' element={<Owner />}/>
            </Route>

            {/* ----- Nośniki ----- */}
            <Route path='/nosniki'>
              <Route index element={<Carriers />} />
              <Route path='new'>
                <Route index element={<CarrierForm />} />
                <Route path=':id' element={<CarrierForm />} />
              </Route>
              <Route path='edit'>
                <Route index element={<CarrierForm />} />
                <Route path=':id' element={<CarrierForm />} />
              </Route>
              <Route path=':id' element={<Carrier />}/>
            </Route>

            {/* ----- Typ Nośnika ----- */}
            <Route path='/typy-nosnikow'>
              <Route index element={<CarrierMaterial />} />
              <Route path='new'>
                <Route index element={<CarrierMaterialForm />} />
                <Route path=':id' element={<CarrierMaterialForm />} />
              </Route>
              <Route path='edit'>
                <Route index element={<CarrierMaterialForm />} />
                <Route path=':id' element={<CarrierMaterialForm />} />
              </Route>
              <Route path=':id' element={<Carrier />} />
            </Route>
          </Route>
        </Route>
        {/* Catch all */}
        <Route path='*' element={<Missing />} />
      </Routes>

    </main>
  );
};
