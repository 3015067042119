/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, } from 'react';
import { useMutation, useQuery, } from 'react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useForm, } from 'react-hook-form';
import { queryClient, } from '../../..';

import {
  ArticleOutlinedIcon,
  Button,
  Fieldset,
  HeaderSide,
  PageContainer,
  Loader,
  ErrorMessage,
  InputText,
  Form,
  InputCheck,
  InputDate,
  SearchBar,
  InputCheckList,
} from '../../../components';

import { CarrierEntity, CarriersResponse, OwnerEntity, OwnerRequest, OwnerResponse, ServerErrors, } from '../../../types';
import { CONFIG, } from '../../../utils/config';
import { ownerSchema, } from './OwnerSchema';

import './ownerForm.css';
import { InputRadio, } from '../../../components/input/InputRadio';

export const OwnerForm = () => {

  /* ------------ Nawigacja -------- */

  const { id, } = useParams();
  const navigate = useNavigate();

  /* ------------ Pobieranie jednego właściciela -------- */

  const {
    isLoading:ownerIsLoading,
    isError: ownerIsError,
    data:owner,
  } = useQuery(
    [ 'owner', id, ], async (): Promise<OwnerEntity> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/owner/${id ?? ''}`, {
          credentials: 'include',
        }
      );
      const { data, }: OwnerResponse = await res.json();
      return data;
    }, {
      onSuccess: async data => {
        await queryClient.invalidateQueries('owners');
        if (data) {
          for (const key in data) {
            if (data.hasOwnProperty(key)) {

              // @ts-ignore
              setValue(
key as keyof OwnerRequest, (data as any)[ key ]
              );
            }
          }
        }
        return data;
      },
      enabled             : !!id,
      refetchOnWindowFocus: false,
    }
  );

  /* ------------ Pobieranie wszytkach nośników do relacji -------- */
  const {
    isLoading:carriersIsLoading,
    isError:carriersIsError,
    data: carriers,
  } = useQuery(
    'carriers', async (): Promise<CarrierEntity[]> => {
      const res: Response = await fetch(
        `${CONFIG.URL}/api/carrier`, {
          credentials: 'include',
        }
      );
      const { data, }: CarriersResponse = await res.json();
      return data;
    } 
  );

  /* ------------ Filtrowanie nośników -------- */
  // wyszukiwarka
  const [ search, setSearch, ] = useState<string>('');
  
  const filteredRows = search? carriers?.filter(carrier => {
    const searchData = [
      carrier.street.toLowerCase(),
      carrier.zipCode.toLowerCase(),
      carrier.city.toLowerCase(),
    ].join(' ');

    return (
      searchData.includes(search.toLowerCase())
    );
  }): carriers;

  /* ------ Przełącznik Edycja Relacji Widok relacji-Widok wybranych ------ */
  
  const [ isOpenCarrier, setIsOpenCarrier, ]=useState<boolean>(false);

  /* ------------ Zmiana tablicy carriers w Owner -------- */

  const handleCheckboxChange = (
    
    carrierCheck:CarrierEntity, isChecked:boolean
  ) => {
    const currentCarriers = watch('carriers') || [];

    const newCarriers = isChecked ? [ ...currentCarriers, carrierCheck, ] : currentCarriers.filter(carrier => carrier.id !== carrierCheck.id);
    
    setValue(
      'carriers', newCarriers
    );
  };

  /* ------------ Stan i walidacja Formularza -------- */

  const { setValue,
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors, }, } = useForm<OwnerRequest>({
      resolver     : yupResolver(ownerSchema),
      mode         : 'onBlur', // Walidacja na zdarzeniu onBlur
      defaultValues: { typeOwner: !id && 'business', },
    });

  /* ------------ Walidacja Backend -------- */

  const [ serverErrors, setServerErrors, ] = useState<ServerErrors | null>(null);
  console.log(serverErrors);

  /* ------------ Wysyłanie Danych do Backendu -------- */

  const { mutate, isLoading, } = useMutation(
    async (data: OwnerRequest) => {
      const response = await fetch(
        `${CONFIG.URL}/api/owner/${id ?? ''}`, {
          method     : id ? 'PATCH' : 'POST',
          headers    : { 'Content-Type': 'application/json', },
          body       : JSON.stringify({ ...data, }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData: { errors: ServerErrors } = await response.json();
        setServerErrors(errorData.errors);
      }
      return response.json();
    }, {
      onSuccess: async () => {
        queryClient.invalidateQueries('owners');
        queryClient.invalidateQueries([ 'owner', id, ]);
        localStorage.removeItem('ownerValues');
        navigate(-1);
      },
      onSettled: newTodo => {
        queryClient.invalidateQueries([ 'owners', newTodo.id, ]);
      },
    }
  );

  /* ------------ Zapisywanie danych do LocalStorage -------- */
  useEffect(
    () => {

      // Wczytywanie danych z LocalStorage przy inicjalizacji
      const savedData = localStorage.getItem('ownerValues');
      if (savedData) {
        const formValues = JSON.parse(savedData);
        for (const key in formValues) {
          setValue(
            key as keyof OwnerRequest, formValues[ key ]
          );
        }
      }
    }, [ setValue, ]
  );

  useEffect(
    () => {

      // Zapisywanie danych do LocalStorage przy zmianie wartości
      const subscription = watch(value => {
        localStorage.setItem(
          'ownerValues', JSON.stringify(value)
        );
      });
      return () => 
        subscription.unsubscribe();
    }, [ watch, ]
  );

  /* ------------ Akcja wysyłania formularza -------- */

  const onSubmit = (data: OwnerRequest) => {
    mutate(data);
  };

  /* ------------ Renderowanie Loader i Błędu -------- */
  
  if (carriersIsLoading) return <Loader />;
  if (ownerIsLoading) return <Loader />;

  if (carriersIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;
  if (ownerIsError) return <p>Wystąpił nieoczekiwany błąd...</p>;

  /* ------------ Renderowanie formularza -------- */

  return (
    <div className='customerForm'>
      <PageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <HeaderSide title='Dodawanie Właściciela' icon={<ArticleOutlinedIcon />} />
          <Button
            className='button delete'
            type='button'
            text='Reset'
            style={{ marginRight: '20px', }}
            handleClick={() => {
              localStorage.removeItem('ownerValues');
              reset();
            }
            }
          />
        </div>

        {!isLoading && <div className='empty' />}
        {isLoading ? (
          <Loader />
        ) : (
          serverErrors?.property === 'NotFoundError' && (
            <ErrorMessage serverError={serverErrors} />
          )
        )}
        <div className='wrapper'>
          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Fieldset legend='Właściciel aktywny:' direction='row' style={{ flex: 1, minWidth: '300px', }}>
              <InputCheck
                id='Właściciel Aktywny'
                name='isActive'
                defaultChecked
                register={register}
              />
            </Fieldset>
            <div style={{ display: 'flex', gap: '16px', width: '100%', }}>
              <Fieldset
                legend='Typ właściciela:' direction='row'
                style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '36px 12px 24px 24px', }}
              >
                <InputRadio
                  id='business'
                  name='typeOwner'
                  register={register}
                  label='Firma'
                  value='business'
                />
                <InputRadio
                  id='private'
                  name='typeOwner'
                  register={register}
                  label='Osoba prywatna'
                  value='private'
                />
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <Fieldset legend='Dane Właściciela:' direction='row' style={{ flex: 1, minWidth: '300px', }} >
                <InputText
                  placeholder={watch('typeOwner') === 'business' ? 'XXX-XXX-XX-XX' : 'RRMMDDXXXXX'}
                  id={watch('typeOwner') === 'business' ? 'NIP' : 'PESEL'}
                  name='NIP'
                  minLength={10}
                  maxLength={13}
                  required
                  register={register}
                  errors={errors.NIP}
                  serverErrors={serverErrors}
                  validate='isUnique'

                  // @ts-ignore
                  value={watch('NIP')}
                />
                <InputText
                  placeholder={watch('typeOwner') === 'business' ? '12345678' : 'ZZC108201'}
                  id={watch('typeOwner') === 'business' ? 'REGON' : 'NR. DOWODU'}
                  name='REGON'
                  minLength={8}
                  maxLength={10}
                  register={register}
                  errors={errors.REGON}
                  value={watch('REGON')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder={watch('typeOwner') === 'business' ? 'FAM' : 'Jan'}
                  id={watch('typeOwner') === 'business' ? 'Nazwa własna Firmy' : 'Imię Nazwisko'}
                  name='name'
                  minLength={3}
                  maxLength={150}
                  required
                  errors={errors.name}
                  register={register}
                  value={watch('name')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder={watch('typeOwner') === 'business' ? 'FunArtMedia' : 'Kowalski'}
                  id={watch('typeOwner') === 'business' ? 'Nazwa Firmy' : 'Imię Nazwisko'}
                  name='nameOwner'
                  minLength={3}
                  maxLength={150}
                  required
                  errors={errors.nameOwner}
                  register={register}
                  value={watch('nameOwner')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='Wolności'
                  id='Ulica'
                  name='street'
                  minLength={3}
                  maxLength={60}
                  required
                  errors={errors.street}
                  register={register}
                  value={watch('street')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='19/15'
                  id='Numer ulicy'
                  name='streetNumber'
                  minLength={1}
                  maxLength={10}
                  required
                  errors={errors.streetNumber}
                  register={register}
                  value={watch('streetNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputText
                  placeholder='XX-XXX'
                  id='Kod Pocztowy'
                  name='zipCode'
                  maxLength={6}
                  required
                  errors={errors.zipCode}
                  register={register}
                  value={watch('zipCode')}
                />
                {/* {serverErrors?.name && <p>{serverErrors.name}</p>} */}
                <InputText
                  placeholder='Zabrze'
                  id='Miasto'
                  name='city'
                  maxLength={60}
                  required
                  errors={errors.city}
                  register={register}
                  value={watch('city')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
              <Fieldset legend='Dane dodatkowe:' direction='row' style={{ flex: 1, minWidth: '300px', }}>
                <InputText
                  placeholder='Imię Nazwisko'
                  id='Osoba do kontaktu'
                  name='contactPerson'
                  minLength={3}
                  maxLength={28}
                  required
                  errors={errors.contactPerson}
                  register={register}
                  value={watch('contactPerson')}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='nazwa@domena.pl'
                  id='Adres Email'
                  name='contactEmail'
                  minLength={5}
                  maxLength={28}
                  errors={errors.contactEmail}
                  required
                  register={register}
                  value={watch('contactEmail')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
                <InputDate
                  id='Data rejestracji firmy'
                  name='registrationDate'
                  min={99999}
                  max={0}
                  errors={errors.registrationDate}
                  register={register}
                  style={{ flex: 1, }}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='Format IBAN'
                  id='Numer konta'
                  name='accountNumber'
                  maxLength={28}
                  errors={errors.accountNumber}
                  register={register}
                  value={watch('accountNumber')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}

                <InputText
                  placeholder='+48 XXX-XXX-XXX'
                  id='Numer Telefonu'
                  name='contactTel'
                  minLength={3}
                  maxLength={60}
                  errors={errors.contactTel}
                  register={register}
                  value={watch('contactTel')}
                />
                {/* {serverErrors[0]?.roperties.registrationDate && <p>{serverErrors.registrationDate}</p>} */}
                <InputText
                  placeholder='www.strona.pl'
                  id='strona www'
                  name='contactWww'
                  minLength={3}
                  maxLength={50}
                  errors={errors.contactWww}
                  register={register}
                  value={watch('contactWww')}
                />
                {/* {serverErrors?.REGON && <p>{serverErrors.REGON}</p>} */}
              </Fieldset>
            </div>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%', }}>
              <Fieldset
                legend='Nośniki:' direction='row'
                style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '0px 12px 24px 12px', }}
              >
                {id && owner?.carriers[ 0 ] && !isOpenCarrier ?<div style={{ width: '100%', }}>
                  <ul className='customerInfo__list' style={{ padding: '40px', width: '60%', margin: '0 auto', }}>
                    {owner?.carriers.map((
                      carrier, index
                    ) => 
                      <li style={{ width: '100%', textAlign: 'left', display: 'flex', justifyContent: 'flex-start', gap: '20px', }} key={carrier.id}>{index + 1}.
                        <span style={{ fontWeight: 'bold', }}> {carrier.city} {carrier.zipCode}</span>
                        ulica:<span style={{ fontWeight: 'bold', }}> {carrier.street} {carrier.streetNumber}</span>
                      wymiary[s x w]:<span style={{ fontWeight: 'bold', }}> {carrier.width} m x {carrier.height} m</span>
                      </li>)}
                    <div style={{ width: '100%', }}>
                      <Button
                        type='button'
                        text='Zmień'
                        style={{ display: 'block', margin: '0 auto', }}
                        onClick={() => 
                          setIsOpenCarrier(true)}/>
                    </div>
                  </ul>
                </div> : <><div className='carrierMaterialDelete__buttons' style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', width: '600px', }}>
                  <SearchBar handleSearch={setSearch} search={search} />
                  <Button
                    type='button'
                    text=' + Dodaj nośnik'
                    className='button add'
                    onClick={() => navigate('/nosniki/new')}
                  />
                </div>
                <div style={{ minHeight: '200px', maxHeight: '20vh', overflow: 'auto', width: '600px', }}> {
                  filteredRows
                    ?.sort((
                      a, b
                    ) => {
                      const aChecked = owner?.carriers?.some(carrierOne => carrierOne.id === a.id);
                      const bChecked = owner?.carriers?.some(carrierOne => carrierOne.id === b.id);

                      if (aChecked && !bChecked) return -1;
                      if (!aChecked && bChecked) return 1;
                      return 0;
                    })
                    .filter(carrier => {
                      return carrier?.owner === null || carrier?.owner?.id === owner?.id;
                    })
                    .map(carrier => (
                      <div key={carrier.id} style={{ display: 'flex', justifyContent: 'center', height: '40px', flexWrap: 'wrap', gap: '20px', }}>
                        <InputCheckList
                          id={carrier.id}
                          name={carrier.id}
                          label={`${carrier.city} -- ${carrier.street} ${carrier.streetNumber}`}
                          value={carrier.id}
                          onChange={e => handleCheckboxChange(
                            carrier, e.target.checked
                          )}
                          defaultChecked={owner?.carriers?.some(carrierOne => carrierOne.id === carrier.id)}
                        />
                      </div>
                    ))}
                </div></>
                }
              </Fieldset>
            </div>
            <div className='carrierMaterialAdd__buttonsContainer' style={{ backgroundColor: 'white', padding: '24px', }}>
              <Button type='submit' text={id ? 'Aktualizacja' : 'Zapisz'} />
              <Button
                className='button cancel'
                type='button'
                text='Anuluj'
                handleClick={() => {
                  localStorage.removeItem('ownerValues');
                  navigate(-1);
                }
                }
              />
            </div>
          </Form>
        </div>
      </PageContainer>
    </div>
  );
};
