import React from 'react';
import './BasicTableHeadRow.css';

interface DataColumnsProps<T> {
  dataColumns: T[];
}

export const BasicTableHeadRow = <T extends { name: string }>({
  dataColumns,
}: DataColumnsProps<T>) => {
  return (
    <tr className='basicTable__headRow'>
      {dataColumns.map((column: T) => 
        (
          <th key={column.name} className='basicTable__headRowHeader'>
            {column.name}
          </th>
        ))}
      <th className='basicTable__headRowHeader basicTable__headRowHeaderEdit '>
        Edycja
      </th>
    </tr>
  );
};
