/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable radix */
import { useState, useEffect, } from 'react';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useMutation, } from 'react-query';
import moment from 'moment';
import { CONFIG, } from '../utils/config';

export const useAutoLogout = () => {
  const [ timeLeft, setTimeLeft, ] = useState(30 * 60 * 1000); // 30 minut
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate, } = useMutation(
    async () => {
      const response = await fetch(
        `${CONFIG.URL}/api/auth/logout`, {
          method     : 'POST',
          credentials: 'include',
        }
      );

      return response.json();
    }, {
      onSettled: () => {
        localStorage.removeItem('login');
        localStorage.setItem(
          'preLogoutPath', location.pathname
        );
        navigate('/login');
      },
    }
  );

  const checkActivity = () => {
    const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0');
    const timePassed = Date.now() - lastActivity;
    const timeRemaining = 30 * 60 * 1000 - timePassed; // 30 minut

    if (timeRemaining <= 0) {
      mutate();
    }
    else {
      setTimeLeft(timeRemaining);
    }
  };

  useEffect(
    () => {

      // Jeśli użytkownik jest na stronie logowania, timer nie jest uruchamiany
      if (location.pathname === '/login') {
        return;
      }

      const interval = setInterval(
        checkActivity, 1000
      ); // Co 1 sekundę
      return () => 
        clearInterval(interval);
    }, [ mutate, location.pathname, ]
  ); // Dodajemy location.pathname do zależności

  // Formatowanie czasu pozostałego do wylogowania
  const formattedTimeLeft = moment.utc(timeLeft).format('mm:ss');

  return `${formattedTimeLeft} min`;
};
