/* eslint-disable react/react-in-jsx-scope */
import { useState, useMemo, ReactNode, } from 'react';
import moment from 'moment';
import { FilterPaymentsContextType, FilterPaymentsState, } from './PaymentFilterTypes';
import { PaymentsFilterContext, } from './PaymentFilterContext';

interface Props {
  children: ReactNode;
}

export const PaymentFilterProvider = ({ children, }: Props) => {
  
  const [ filterPayments, setFilterPayments, ] = useState<FilterPaymentsState>({
    search   : '',
    isPayment: false,
    startDate: moment().startOf('month')
      .format(),
    endDate: moment().endOf('month')
      .format(),
  });

  const contextValue = useMemo<FilterPaymentsContextType>(
    () => 
      [ filterPayments, setFilterPayments, ], [ filterPayments, ]
  );

  return (
    <PaymentsFilterContext.Provider value={contextValue}>
      {children}
    </PaymentsFilterContext.Provider>
  );
};