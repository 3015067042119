import React from 'react';

interface Props {
  id: string;
}

export const CustomerPayment = ({ id, }:Props) => {
  return (
    <div>
      płatności { id }
    </div>
  );
};
