import * as yup from 'yup';

// Schemat walidacji zakładania i aktualizacji Customer (Klient)
export const customerSchema= yup.object().shape({
  isActive: yup.boolean()
    .required(),
  NIP: yup
    .string()
    .min(
      10, 'NIP powinien zawierać min 10 znaków'
    )
    .max(
      13, 'NIP powinien zawierać max 13 znaków'
    )
    .required('NIP jest wymagany.'),
  
  REGON: yup
    .string()
    .min(
      8, 'REGON powinien zawierać max 8 znaków.'
    )
    .max(
      9, 'REGON powinien zawierać max 9 znaków.'
    ),

  name: yup
    .string()
    .min(
      3, 'Nazwa klienta powinien zawierać max 3 znaki'
    )
    .max(
      150, 'Nazwa klienta powinien zawierać max 150 znaków'
    )
    .required('Nazwa klient jest wymagana.'),

  nameCustomer: yup
    .string()
    .min(
      3, 'Nazwa firmy powinien zawierać max 3 znaki'
    )
    .max(
      150, 'Nazwa firmy powinien zawierać max 150 znaków'
    )
    .required('Nazwa firmy jest wymagana.'),

  street: yup
    .string()
    .min(
      3, 'Nazwa ulicy powinien zawierać min 3 znaki.'
    )
    .max(
      60, 'Nazwa ulicy powinien zawierać max 60 znaków.'
    )
    .required('Nazwa ulicy jest wymagana.'),
  
  streetNumber: yup
    .string()
    .min(
      1, 'Numer ulic powinien zawierać min 1 znak'
    )
    .max(
      10, 'Numer ulicy powinien zawierać max 10 znaków'
    )
    .required('Numer ulicy nie może być pusty.'),

  zipCode: yup
    .string()
    .matches(
      /^[0-9]{2}-[0-9]{3}$/, 'Kod pocztowy powinien mieć format XX-XXX'
    )
    .required('Kod pocztowy nie może być pusty.'),
  
  city: yup
    .string()
    .max(
      60, 'Miasto może mieć maksymalnie 60 znaków.'
    )
    .required('Miasto jest wymagane.'),

  contactPerson: yup
    .string()
    .min(
      3, 'Osoba kontaktowa musi mieć co najmniej 3 znaki.'
    )
    .max(
      28, 'Osoba kontaktowa może mieć maksymalnie 28 znaków.'
    )
    .required('Osoba do kontaktu jest wymagana.'),
    
  contactEmail: yup
    .string()
    .email('Nieprawidłowy format adresu email.')
    .required('Adres email jest wymagany.'),
    
  registrationDate: yup
    .date()
    .max(
      new Date(), 'Data rejestracji nie może być w przyszłości.'
    )
    .required('Data rejestracji jest wymagana.'),
  
  accountNumber: yup
    .string(),

  contactTel: yup
    .string(),
  
  contactWww: yup
    .string()
    .url('Nieprawidłowy format adresu URL.')
    .max(
      50, 'Adres WWW może mieć maksymalnie 50 znaków.'
    ),
});
