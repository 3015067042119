/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import React, {
  BaseSyntheticEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import './Input.css';
import moment from 'moment';
import { FieldError, } from 'react-hook-form';
import { ErrorInput, } from '../errors/ErrorInput';
import { ServerErrors, } from '../../types';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  min?: number;
  max?: number;
  name: string;
  id: string;
  focus?: boolean;
  register?: any;
  errors?: FieldError | undefined;
  handleChange?: (e: BaseSyntheticEvent) => void;
  serverErrors?: ServerErrors | null;
  validate?:string
}

export const InputDate = ({
  name,
  id,
  max,
  min,
  focus,
  handleChange,
  required,
  register,
  errors,
  serverErrors,
  validate,
  ...rest
}: InputProps) => {

  // Ref
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      setTimeout(
        () => {
          focus && inputRef?.current?.focus();
        }, 200
      );
    }, [ focus, ]
  );
  return (
    <div className='input'>
      <input
        ref={inputRef}
        className={`input__data ${required && 'required'}`}
        type='date'
        name={name}
        id={id}
        defaultValue={moment().format('YYYY-MM-DD')}
        autoComplete='off'
        tabIndex={0}
        min={moment().subtract(
          min, 'days'
        )
          .format('YYYY-MM-DD')}
        max={moment().add(
          max, 'days'
        )
          .format('YYYY-MM-DD')}
        pattern='\d{4}-\d{2}-\d{2}'
        onChange={handleChange}
        required={required}
        {...register(name)}
        {...rest}
      />
      <label className={`input__label ${required && 'required'}`} htmlFor={id}>
        {`${id} ${required ? '*' : ''}`}
      </label>
      {!errors && !serverErrors && <div style={{ height: '20px', }}/>}
      <ErrorInput
        errors={errors}
        serverErrors={serverErrors}
        errorName={name}
        validate={validate}
      />
    </div>
  );
};
