import * as yup from 'yup';
import { LoginRequest, } from '../../types';

// Schemat walidacji logowania
export const loginSchema: yup.ObjectSchema<
  LoginRequest,
  yup.AnyObject,
  {
    name: undefined;
    password: undefined;
  },
  ''
> = yup.object().shape({
  name    : yup.string().required('Imię jest wymagane'),
  password: yup.string().required('Hasło jest wymagane'),
});
