/* eslint-disable react/display-name */
import React from 'react';
import { useAutoLogout, } from '../../hooks/useAutoLogout';

export const TimerDisplay = React.memo(() => {
  const formattedTimeLeft = useAutoLogout();

  return (
    <div>
      Czas do wylogowania: {formattedTimeLeft}
    </div>
  );
});